import { render } from "@testing-library/react";
import { Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import directive from "remark-directive";
import customDirectives from "./customDirectives";
import rehypeRaw from 'rehype-raw'
import { getPassages } from "./datajsx.js";
import Note from "./Note.jsx";
import Markdown from "./Markdown.jsx";
import HtmlComponent from "./HtmlComponent.jsx";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2, domToReact } from 'html-react-parser';  


import { useParams } from 'react-router-dom';
import React, { useState } from "react";

function Passage() {
    const options = {
        decodeEntities: true,
        replace: (domNode) => {
            if (!domNode.attribs) {
                return;
            }
            if (domNode.type === "tag" && domNode.name === "note") {
                console.log(domNode.name);
                console.log(domNode.attribs);
                console.log('reached');
                return (
                  <a href="#" onClick={() => setNoteId(domNode.attribs["noteid"])}>
                    {domToReact(domNode.children, options)}
                  </a>
                );
            }
        }
    };

      
    const components = {
        note({ text, id }) {
            return <Link to="#" onClick={() => setNoteId(id)}>{text}</Link>
        }
    };


    let { passageId } = useParams()

    const [noteId, setNoteId] = useState('');

    let passages = getPassages();
    let passageText = passages.filter(p => p.chapter == passageId).sort((a, b) => (a.order - b.order)).reduce((acc, p) => acc += p.text, "");

    return(
        <React.Fragment>

        <div id="text">
            <div className="inner">
                <HtmlComponent options={options} text={passageText} />
            </div>
        </div>
        <Note noteId={noteId} options={options}/>
        </React.Fragment>);
}

export default Passage;