import logo from './logo.svg';
import './App.css';
import { Outlet, Link } from "react-router-dom";
import Header from "./Header.js"
import Footer from "./Footer.js"
function App() {
  return (
    <div className="App">
      <Header/>
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
