import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import App from './App';
import Passage from './Passage';
import About from './About';
import Bibliography from './Bibliography';
import Updates from './Updates';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
		    <Route exact path="/"  element={<App />}>
          <Route path = "/chapter/:passageId" element={<Passage />}/>
          <Route exact path ="/about" element={<About />}/>
          <Route exact path ="/bibliography" element={<Bibliography />}/>
          <Route exact path ="/updates" element={<Updates />}/>
          <Route path="/" element={<Navigate to="/about" replace />} />
        </Route>
	    </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
