import { Link } from "react-router-dom";
import React from "react";
function Header() {
    return (
	<React.Fragment>
	<div id="header">The Dracula Project</div>
    <div id="headermenu">
		<ul id="dropdown">
			 <li className="topnav"><a href="#">By Chapter</a>
				<ul>
					<div className="column">
						<li><Link to="chapter/200">Title</Link></li>
						<li><Link to="chapter/203">Preface</Link></li>
						<li><Link to="chapter/204">Dedication</Link></li>
						<li><Link to="chapter/205">Intr. Note</Link></li>
						<li><Link to="chapter/1">Chapter I</Link></li>
						<li><Link to="chapter/2">Chapter II</Link></li>
						<li><Link to="chapter/3">Chapter III</Link></li>
						<li><Link to="chapter/4">Chapter IV</Link></li>
						<li><Link to="chapter/5">Chapter V</Link></li>
						<li><Link to="chapter/6">Chapter VI</Link></li>
						<li><Link to="chapter/7">Chapter VII</Link></li>
						<li><Link to="chapter/8">Chapter VIII</Link></li>
						<li><Link to="chapter/9">Chapter IX</Link></li>
						<li><Link to="chapter/10">Chapter X</Link></li>
						<li><Link to="chapter/11">Chapter XI</Link></li>
						<li><Link to="chapter/12">Chapter XII</Link></li>
					</div>
					<div className="column">
					<li><Link to="chapter/13">Chapter XIII</Link></li>
						<li><Link to="chapter/14">Chapter XIV</Link></li>
						<li><Link to="chapter/15">Chapter XV</Link></li>
						<li><Link to="chapter/16">Chapter XVI</Link></li>
						<li><Link to="chapter/17">Chapter XVII</Link></li>
						<li><Link to="chapter/18">Chapter XVIII</Link></li>
						<li><Link to="chapter/19">Chapter XIX</Link></li>
						<li><Link to="chapter/20">Chapter XX</Link></li>
						<li><Link to="chapter/21">Chapter XXI</Link></li>
						<li><Link to="chapter/22">Chapter XXII</Link></li>
						<li><Link to="chapter/23">Chapter XXIII</Link></li>
						<li><Link to="chapter/24">Chapter XXIV</Link></li>
						<li><Link to="chapter/25">Chapter XXV</Link></li>
						<li><Link to="chapter/26">Chapter XXVI</Link></li>
						<li><Link to="chapter/27">Chapter XXVII</Link></li>
						<li><Link to="chapter/206">Dracula's Guest</Link></li>
=					</div>
       			</ul>
			</li>
		</ul> 
		<ul id="dropdown">
			 <li className="topnav"><a href="#">Time Line</a>
				<ul>
					<div className="column" /*style="width:128px;"*/>
						<li><a href="#">March</a></li>
						<li><a href="#">April</a></li>
						<li><a href="#">May</a></li>
						<li><a href="#">June</a></li>
						<li><a href="#">July</a></li>
						<li><a href="#">August</a></li>
						<li><a href="#">September</a></li>
						<li><a href="#">October</a></li>
						<li><a href="#">November</a></li>
					</div>
         			</ul>
			</li>
		</ul> 
		<ul id="dropdown">
			 <li className="topnav"><Link to="/about">About</Link></li>
		</ul> 
		<ul id="dropdown">
			 <li className="topnav"><Link to="/updates">Updates</Link></li>
		</ul> 
		<ul id="dropdown" /*style="border:0;"*/>
			 <li className="topnav"><Link to="/bibliography">Bibliography</Link></li>
		</ul> 
</div>
</React.Fragment>);
}

export default Header;