import { Link } from "react-router-dom";
import React from "react";
function Footer() {
	return (
		<div id="footer">
			<br />
			Created by I. and L. Davydov (thedraculaprojectATgmailDOTcom)
			<br />
			Developed from a CSS layout created by Stuart Nicholls ~ (Copyright ©2004/5/6 All rights reserved.&nbsp;)
		</div>
	);
}

export default Footer;