import { render } from "@testing-library/react";
import { getNotes } from "./datajsx.js";
import { Link } from "react-router-dom";

import Markdown from "./Markdown.jsx";
import HtmlComponent from "./HtmlComponent.jsx";
import { useParams } from 'react-router-dom';

function Note(props) {
    if (props.noteId) {
    let notes = getNotes();
    let noteText = notes.find(n => n.external_id == props.noteId)?.text;
    return(
        <div id="footnotes">
            <HtmlComponent options={props.options} text={noteText} />
        </div>);
    }
}

export default Note;