function Header() {
    return (<div id="text">
<div class="inner">
<h1>Updates</h1>

<h2>25 May 2022</h2>
<ul>
    <li>Site works again!</li>
</ul>
<h2>26 December 2014</h2>
<ul>
<li>All chapters are now complete and accessible.
</li></ul>

</div>
</div>)};

export default Header;