function Bibliography() {
return (<div id="text" className="biblio">
<div class="inner">
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://traditionsacrosseurope.wordpress.com/2008/04/26/23-april-in-romania-sfantul-gheorghe-st-george-day/">
        "23 April in Romania: Sfantul Gheorghe - St. George Day."
    </a>
    <em>Traditions Across Europe,</em>
    26 Apr. 2008. Web. 4 May 2014.
</p>
<p>
    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://lazarus.elte.hu/hun/digkonyv/topo/3felmeres.htm">3rd Military Mapping of Austria-Hungary.</a> </em>
    Map. [ca. 1:200,000] <em>ELTE Térképtudományi és Geoinformatikai Tanszék.</em> Web. 10 Jun 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.royalparks.org.uk/parks/green-park/about-green-park">"About Green Park."</a>
    <em>The Royal Parks, </em>
    2012. Web. 8 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.rspca-scarborough.org.uk/about-us/">"About Us."</a>
    <em>Royal Society for the Prevention of Cruelty to Animals</em>
    (Scarborough Branch). Web. 3 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://newspaperarchive.com/jewish-standard/1889-09-25/page-9">"Adelphi Stage Jew."</a>
    <em>The Jewish Standard</em>
    25 Sep. 1889: 9.<em> newspaperarchive.com. </em>Web. 28 Oct. 2013.
</p>
<p>
Afanas'ev, Aleksandr N. "Poetic Views of the Slavs Regarding Nature." Trans. Jan L. Perkowski.    <em>Vampire Lore: From the Writings of Jan Louis Perkowski.</em> Bloomington: Slavica Publishers, 2006: 193-211. Print.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.learn2grow.com/plants/allium-sativum/">"Allium Sativum."</a>
    <em>Learn2Grow, </em>
    2006-2014. Web. 6 Jun. 2014.
</p>
<p>
Ancel, Jean et al., Ed.    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.jewishgen.org/yizkor/pinkas_romania/pinkas_romania1.html"><em>Encyclopaedia of Jewish Communities in Romania.</em></a> 2 vols.
    Jerusalem: Yad Vashem, 1969. Web. 31 Oct. 2013.
</p>
<p>
    Andem, James. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=AZ8_AAAAYAAJ">A Practical Guide for the Use of the Edison Phonograph.</a> </em>Cincinnati: C.
    I. Arebbiel &amp; Co., 1892. <em>Google Books.</em> Web. 18 Oct. 2013.
</p>
<p>
    Anonymous. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.litgothic.com/Texts/mysterious_stranger.pdf">"The Mysterious Stranger."</a> <em>The Literary Gothic.</em> Web. 6 Mar. 2013.
</p>
<p>
    <em>Anton, George. dir.</em>
    <em> Dracula.</em>
    <em> Anton Films, 2009. Film.</em>
</p>
<p>
    <em>Argento, Dario, dir. </em>
    <em>Dracula 3D.</em>
    <em> Enrique Cerezo Producciones, 2012. Film.</em>
</p>
<p>
    Artenie, Christina.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.academia.edu/3342068/Draculas_Kitchen_a_Glossary_of_Transylvanian_Cuisine_Language_and_Ethnography">
        "Dracula's Kitchen: A Glossary of Transylvanian Cuisine, Language, and Ethnography."
    </a>
    <em>The Universal Vampire.</em>
    Eds. Barbara Brodman and James E. Doan. Madison: Fairleigh Dickinson, 2013. 45- 56. <em>academia.edu. </em>Web. 4 Nov. 2013.
</p>
<p>
    Auerbach, Nina and David J. Skal, eds.<em> Dracula</em>. New York: W. W. Norton &amp; Company, 1997. Print.
</p>
<p>
    Augustine of Hippo<em>. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=-OQPz6gtTgcC">The Confessions of St. Augustine, Bishop of Hippo.</a></em> Trans. J. G.
    Pilkington. Edinburgh, T &amp; T Clark, 1876. <em>Google Books</em>. Web. 6 May. 2014.
</p>
<p>
    <em>Badham, John, dir. </em>
    <em>Dracula</em>
    <em>. Universal Pictures, 1979. Film.</em>
</p>

<p>Baedeker, Karl. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=kc8NAAAAYAAJ"><em>Austria, Including Hungary, Transylvania, Dalmatia and Bosnia: Handbook for Travellers</em></a>. 8th rev. ed. Leipsic: Karl Baedeker, 1896. <em>Internet Archive.</em> Web. 15 Jun. 2013.</p>

<p>Baedeker, Karl. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://archive.org/details/belgiumandholland00karl">Belgium and Holland: Handbook for Travellers</a></em>. 10th rev. ed. Leipsic: Karl Baedecker, 1891. <em>Internet Archive.</em> Web. 24 Jul. 2013.</p>

<p>Baedeker, Karl.<em> <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=vccNAAAAYAAJ">Great Britain: Handbook for Travellers</a></em>. 2nd rev. ed. Leipsic: Karl Baedecker, 1890. <em>Google Books</em>. Web. 24 Jul. 2013.</p>

<p>Baedeker, Karl. <em>London and Its Environs: Handbook for Travellers. </em>8th rev. ed. Leipsic: Karl Baedeker, 1892. <em>Google Books</em>. Web. 29 Jun. 2013.</p>

<p>Baedeker, Karl. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=EOM9AAAAIAAJ">London and Its Environs: Handbook for Travellers</a></em>. 9th rev. ed. Leipsic: Karl Baedeker, 1894. <em>Google Books</em>. Web. 29 Jun. 2013.</p>

<p>Baedeker, Karl. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://archive.org/stream/01664173.5382.emory.edu/01664173_5382#page/n5/mode/2up"><em>London and Its Environs: Handbook for Travellers</em></a>. 10th rev. ed. Leipsic: Karl Baedeker, 1896. <em>Internet Archive</em>. Web. 29 Jun. 2013.</p>

<p>Baedeker, Karl. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=EkYdAAAAIAAJ">Northern Germany, as Far as the Bavarian and Austrian Frontiers: Handbook for Travellers</a></em>. 11th rev. ed. Leipsic: Karl Baedeker, 1893. <em>Google Books</em>. Web. 5 Jun. 2014.</p>

<p>Baedeker, Karl. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=ceINAAAAYAAJ"><em>Southern Germany and Austria, including Hungary, Dalmatia and Bosnia: A Handbook for Travellers</em></a>. Leipsic: Karl Baedeker, 1891. <em>Google Books.</em> Web. 28 Jun. 2013.</p>

<p>Baedeker, Karl. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=J3o2AAAAMAAJ"><em>Southern Germany and Austria, including Hungary and Transylvania: A Handbook for Travellers</em></a>. 4th rev. ed. Leipsic: Karl Baedeker, 1880. <em>Google Books</em>. Web. 15 Jun. 2013.</p>

<p>Baedeker, Karl. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=7sINAAAAYAAJ"><em>Southern Germany and Austria, including Hungary and Transylvania: A Handbook for Travellers</em></a>. 6th rev. ed. Leipsic: Karl Baedeker, 1887. <em>Google Books</em>. Web. 15 Jun. 2013.</p>

<p>Baedeker, Karl. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://archive.org/details/16840876.5294.emory.edu"> <em>Southern Germany and Austria, including the Eastern Alps: A Handbook for Travellers</em></a>. 3rd rev. ed. Leipsic: Karl Baedeker, 1873. <em>Google Books</em>. Web. 15 Jun. 2013.</p>

<p>
Baines, Frederick Ebenezer, ed.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=j-MuAAAAMAAJ"><em>Records of the Manor, Parish, and Borough of Hampstead, in the County of </em></a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=j-MuAAAAMAAJ"><em> </em></a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=j-MuAAAAMAAJ"><em>London to December 31st, 1889.</em></a> London: Richard Clay and Sons, 1890.    <em>Google Books</em>. Web. 28 Sep. 2013.
</p>
<p>
    Balassa, Iván and Gyula Ortutay. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://mek.oszk.hu/02700/02790/html/"><em>Hungarian Ethnography and Folklore.</em></a> Budapest: Corvina
    Kiadó, 1979. <em>Hungarian Electronic Library</em><em>. </em>Web. 30 Oct. 2013.
</p>
<p>
Ballantyne, Coco.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.scientificamerican.com/article.cfm?id=fact-or-fiction-stress-causes-gray-hair">"Fact or Fiction?: Stress Causes Gray Hair."</a>    <em>Scientific American </em>24 Oct. 2007. <em>Scientific American.</em> Web. 18 Oct. 2013.
</p>
<p>
    Bane, Theresa. <em>The Encyclopedia of Vampire Mythology.</em> Jefferson: McFarland &amp; Company: 2010. Print.
</p>
<p>
    Barber, "The Real Vampire." <em>Dracula: A Documentary Volume.</em> Ed. Elizabeth Miller. New York: Pegasus Books, 2009. 52-56. Print.
</p>
<p>
    Barger, Andrew, ed. <em>The Best Vampire Stories 1800-1849: A Classic Vampire Anthology. </em>eBook ed. Bottletree Books LLC, 2012. File.
</p>
<p>
Baring-Gould, Sabine.    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=m4EAAAAAMAAJ"><em>The Book of Were-Wolves: Being an Account of a Terrible Superstition</em></a>. London: Smith,
    Elder and Co., 1865. <em>Google Books</em>. Web. 14 Aug. 2013.
</p>
<p>
    Baring-Gould, Sabine. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=Ec5y2y1b6T0C"><em>Curious Myths of the Middle Ages</em></a>, 2nd series. London:
    Rinvingtons, 1868. <em>Google Books</em>. Web. 4 Oct. 2013.
</p>
<p>
    Bartholow, Roberts. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=PyoTAAAAYAAJ&amp;pg=PA113">"The Treatment of Cholera."</a> <em>The Medical News </em>62.5 (4
    Feb. 1893): 113-117. <em>Google Books</em>. Web. 13 Sep. 2013.
</p>
<p>
Bartlett, Edward. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=ePYYAAAAYAAJ&amp;pg=PA1#v=onepage&amp;q&amp;f=false">"Biographical Notes."</a>    <em>Wild Animals in Captivity: Being an Account of the Habits, Food, Management of the Beast and Birds at the 'Zoo' with Reminiscences and Anecdotes. </em>
    London: Chapman and Hall, 1898. 1-10. <em>Google Books.</em> Web. 8 Nov. 2013.
</p>
<p>
    Beadle, Brian.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.yorkpress.co.uk/lifestyle/countrywalks/10267761.Country_walk_to_Mulgrave_Castle__Sandsend/">
        "Country Walk to Mulgrave Castle, Sandsend."
    </a>
    <em>The York Press</em>
    6 Mar. 2013<em>. </em>Web. 20 Nov. 2013.
</p>
<p>
Beck, Edmund. Ed.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://en.wikipedia.org/wiki/Apocalypse_of_Pseudo-Ephraem#Translation_of_Pseudo-Ephraem">"Sermon of Pseudo-Ephraem on the End of the World."</a>    <em> Des heiligen Ephraem des Syrers Sermones III, </em>Louvain: Secrétariat du Corpus SCO, 1972. 60-71. <em>Wikipedia. </em>Web. 4 May. 2014.
</p>
<p>
    Beckson, Karl. <em>London in the 1890s: A Cultural History.</em> New York: W. W. Norton &amp; Company, 1992. Print.
</p>
<p>
    Belford, Barbara. <em>Bram Stoker and the Man Who was Dracula. </em>Cambridge: Da Capo Press, 2002. Print.
</p>
<p>
    Bennett, George, Ed.<em> <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=pe0jAAAAMAAJ">An Illustrated History of British Guiana.</a></em> Georgetown, Demerara:
    Richardson and Co., 1866. <em>Google Books</em>. Web. 27 Sep. 2013.
</p>
<p>
    Bennion, Elisabeth. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=DftZWlVnzH8C"><em>Antique Medical Instruments.</em></a> London: Southby Parke Bernet
    Publications, 1979. <em>Google Books</em>. Web. 10 Mar. 2013. <span >[Preview Only]</span>
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.pastscape.org.uk/hob.aspx?hob_id=1314101">"Bethnal Green Station."</a>
    <em>Pastscape, </em>
    2007.<em> </em>Web 9 Jun. 2014.
</p>
<p>
Bergen, Fanny D., Ed.    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=B8vYAAAAMAAJ"><em>Current Superstitions: Collected from the Oral Tradition of English Speaking Folk.</em></a>
    Boston: Houghton, Mifflin and Company, 1896. <em>Google Books. </em>Web. 10 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://biblehub.com/"><em>Bible Hub.</em></a>
    <em>Online Parallel Bible Project. </em>
    Web. 6 May. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=MYjNAAAAMAAJ&amp;pg=PA90#v=onepage&amp;q&amp;f=false">"The Bicycle Duly Considered."</a>
    <em>The Chautauquan </em>
    24 (Oct. 1896 to Mar. 1897): 90-1. <em>Google Books.</em> Web. 23 Oct. 2013.
</p>
<p>
Bird, Isabella. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=0G1_H8pon98C"><em>The Golden Chersonese.</em></a> New York: G.P. Putnam's Sons, 1883.    <em>Google Books.</em> Web. 15 Jun. 2013.
</p>
<p>
    Bird, Isabella.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=28QYAAAAYAAJ">
        <em>Korea &amp; Her Neighbours: A Narrative of Travel, with an Account of the Recent Vicissitudes </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=28QYAAAAYAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=28QYAAAAYAAJ"><em>and Present Position of the Country.</em></a>
    2 vols. London: John Murray, 1898. <em>Google Books</em>. Web. 7 Nov. 2013.
</p>
<p>
    Bizonfy, Ferencz. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=DuAnAAAAYAAJ"><em>English-Hungarian Dictionary</em></a>.<em> </em>2 vols.<em> </em>Budapest:
    Franklin-Társult, 1886. <em>Google Books.</em> Web. 18 Jun. 2013
</p>
<p>
    Black, Adam and Charles Black. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=_rgHAAAAQAAJ"><em>Black's Guide to Scarborough, Whitby and Harrogate.</em></a>
    15th ed. Edinburgh: Adam and Charles Black, 1883. <em>Google Books</em>. Web. 24 Jul. 2013.
</p>
<p>
Black, <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=agUVAAAAQAAJ&amp;pg=RA1-PA36#v=onepage&amp;q&amp;f=false">"On the Etymology of Carfax."</a>    <em>Report of the Proceedings of the Oxford University Genealogical and Heraldic Society </em>2 (16 Jun. 1835): 36-42. <em>Google Books</em>. Web. 15 Sep.
    2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://thelawdictionary.org/"><em>Black's Law Dictionary.</em></a>
    2nd ed. Web. 3 Jun. 2014.
</p>
<p>
Blasce, J. H. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=UBMwAAAAYAAJ">A Few Steps to a Complete Dictionary of English Dialects.</a> </em>Hamburg: 1890.    <em> Google Books</em>. Web. 7 Jun. 2014.
</p>
<p>
Bondeson, Jan.<a target="_blank" href="http:http://www.forteantimes.com/features/articles/477/toad_in_the_hole.html">"Toad in the Hole."</a>    <em>Fortean Times</em> Jun. 2007. <em>Internet Archive.</em> Web. 27 Sep. 2013.
</p>
<p>
    Boner, Charles. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=vI8AAAAAcAAJ"><em>Transylvania: Its Products and its People</em></a>. London: Longman, Green,
    Reader &amp; Dyer, 1865.
</p>
<p>
    <em>Google Books.</em>
    Web. 15 Jun. 2013.
</p>
<p>
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://justus.anglican.org/resources/bcp/1892Standard/">
            The Book of Common Prayer from The Original Manuscript Attached to the Act of Uniformity
        </a>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://justus.anglican.org/resources/bcp/1892Standard/">of 1662.</a>
    </em>
    London: Eyre &amp; Spottiswoode, 1892. <em>justus.anglican.org.</em> Web. 18 Oct. 2013.
</p>
<p>
    Boucicault, Dion. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=k-_cOfX3_zcC">The Phantom.</a> </em>New York: Samuel French, 1857. <em>Google Books</em>.
    Web. 29 Jul. 2014.
</p>
<p>
    Boylan, Andy. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://vamped.org/2014/04/05/stoker-bat/">"Stoker and the Bat."</a> <em>Vamped,</em> 5 Apr. 2014. Web. 24 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=w3dKAAAAYAAJ">
        <em>Bradshaw's Continental Railway, Steam Transit, and General Guide, for Travellers Through Europe; Containing </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=w3dKAAAAYAAJ"><em> </em></a>
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=w3dKAAAAYAAJ">
            the Official Time Tables of all the Continental Railways, Steam-Boats, Diligences, Mail Coaches, &amp;c.
        </a>
    </em>
    London: W. J. Adams &amp; Sons, Jul. 1887. <em>Google Books.</em> Web. 24 Aug. 2013.
</p>
<p>
    <em>Bradshaw's Rail Times for Great Britain and Ireland: December 1895.</em>
    Midhurst: Middleton Press, 2011. Print.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=W1YoAAAAYAAJ">
        <em>Bradshaw's Through Routes to the Capitals of the World, and Overland Guide to India, Persia and the Far East: </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=W1YoAAAAYAAJ"><em> </em></a>
    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=W1YoAAAAYAAJ">Handbook of Indian, Colonial, and Foreign Travel.</a> </em>
    London: Henry Blacklock &amp; Co., 1903. <em>Google Books.</em> Web. 15 Oct. 2013.
</p>
<p>
    Brewer, Ebenezer.
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=QsA3AQAAMAA">D</a>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=QsA3AQAAMAA">ictionary of Phrase and Fable, Giving the Source, or Origin of Common Phrases, Allusions, </a>
    </em>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=QsA3AQAAMAA"> </a>
    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=QsA3AQAAMAA">and Words that have a Tale to Tell.</a> </em>
    Rev ed. 2 vols. London: Cassell and Company, Ltd, 1895. <em>Google Books</em>. Web. 20 Nov. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.bankofengland.co.uk/banknotes/%20pages/about/history.aspx">"A Brief History of Banknotes."</a>
    <em>Bank of England</em>
    . Web. 10 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://www.ibms.org/go/nm:history-blood-transfusion">"A Brief History of Blood Transfusion."</a>
    <em>The Biomedical Scientist,</em>
    Nov. 2005. Web. 24 May 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.elte.hu/en/elte_brief_history">"Brief History of ELTE."</a>
    Eötvös Loránd University, 20 Dec. 2012.<em> </em>Web. 8 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=Z9QMAQAAIAAJ"><em>British and Foreign State Papers: 1872-1873</em></a>
    , Vol. 58. Comp. Librarian and Keeper of the Papers. London: William Ridgway, 1879. <em>Google Books.</em> Web. 7 Sep. 2013.
</p>
<p>
    Brittain, Colin. "<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.eskside.co.uk/dive_whitby/the_wrecks.htm">Whitby Wrecks."</a> <em>Whitby Dive Site,</em> 1999-2013.<em> </em>Web. 2
    Jun. 2014.
</p>
<p>
Browne, Thomas.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://penelope.uchicago.edu/pseudodoxia/"><em>Pseudodoxia Epidemica: or, Enquires into Very Many Received Tenents and Commonly </em></a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://penelope.uchicago.edu/pseudodoxia/"><em> </em></a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://penelope.uchicago.edu/pseudodoxia/"><em>Presumed Truths</em></a>, 6th ed.
    1672. <em>University of Chicago.</em> Web. 11 Oct. 2013.
</p>
<p>
    Browning, Elizabeth Barrett. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=xrw8AAAAYAAJ"><em>Elizabeth Barrett Browning's Poetical Works</em></a>.<em> </em>6
    vols. London: Smith, Elder &amp; Co., 1889. <em>Google Books</em>. Web. 6 Sep. 2013.
</p>
<p>
    Browning, John Edgar, ed. <em>Bram Stoker's Dracula: The Critical Feast.</em> Berkley: Apocryphile Press, 2011. Print.
</p>
<p>
    <em>Browning, Tod, dir. </em>
    <em>Dracula.</em>
    <em> Universal Pictures, 1931. Film.</em>
</p>
<p>
Bulley, Amy and Margaret Whitley.<em> <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=WbZOAAAAMAAJ">Women's Work</a>.</em> London: Methuen &amp; Co., 1894.    <em>Google Books</em>. Web. 29 Jun. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=eWQPAQAAIAAJ&amp;pg=PA220#v=onepage&amp;q&amp;f=false">"Burger and Boucicault."</a>
    <em>Punch </em>
    78 (1880): 220. <em>Google Books.</em> Web. 23 Jun. 2013.
</p>
<p>
    Burns, Stu. "A Short History of Vampire Folklore." <em>Dracula: A Documentary Volume.</em> Ed. Elizabeth Miller. New York: Pegasus Books, 2009. 29-31.
    Print.
</p>
<p>
    Byron, George Gordon. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=q4QR8v_hOigC"><em>The Poetical Works of Lord Byron: Complete in One Volume.</em></a>
    London: John Murray, 1846. <em>Google Books</em>. Web. 27 Sep. 2013.
</p>
<p>
    Byron, Glennis, ed. <em>Dracula.</em> Ontario: Broadview Press, 1998. File.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=9F0CAAAAIAAJ&amp;pg=PA23">"By-Words."</a>
    <em>The National Magazine: Devoted to Literature, Art, and Religion </em>
    2 (Jan. - Jun. 1858): 232. <em>Google Books.</em> Web. 13 Aug. 2013
</p>
<p>
C., F. P. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=as9cD1GxF-UC&amp;pg=PA228#v=onepage&amp;q=cetripetal&amp;f=false">"Celibacy v. Marriage."</a>    <em>Fraser's Magazine </em>65 (Jan. - Jun. 1862): 228-235. <em>Google Books</em>. Web. 7 Nov. 2013.
</p>
<p>
Cain, Jimmie E.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=VomtVOkkPDwC"><em>Bram Stoker and Russophobia: Evidence of the British Fear of Russia in </em></a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=VomtVOkkPDwC">Dracula</a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=VomtVOkkPDwC"><em> and The Lady </em></a>    <em> </em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=VomtVOkkPDwC"><em>of the Shroud.</em></a> Jefferson, NC: McFarland, 2006. <em>Google Books.</em> Web. 8
    May. 2014. <span >[Preview Only]</span>
</p>
<p>
    Caine, Hall. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=DitLAAAAIAAJ">Capt'n Davy's Honeymoon; The Last Confession; And, The Blind Mother.</a> L</em>
    ondon: William Heinemann, 1893. <em>Google Books</em>. Web. 5 Mar. 2014.
</p>
<p>
    Caine, Hall.<em> <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=Ti9LAAAAIAAJ">My Story.</a> </em>London: William Heinemann, 1908.<em>Google Books.</em> Web. 8
    Jun. 2014.
</p>
<p>
Calmet, Augustin.<em> <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=A5pBAAAAYAAJ">Calmet's Dictionary of the Holy Bible</a></em>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=A5pBAAAAYAAJ">.</a> Ed. Charles Taylor. London: Holdsworth and Ball, 1832. <em>Google Books.</em> Web. 15 Aug.
    2013.
</p>
<p>
    Calmet, Augustin.
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=Z1GqcY9ow3QC">The Phantom World: The History and Philosophy of Spirits, Apparitions, &amp;c., &amp;c.</a>
    </em>
    Philadelphia: A. Hart, 1850. <em>Google Books. </em>Web. 19 May. 2014.
</p>
<p>
    Calvert, Walter.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=8MQxAQAAMAAJ">
        <em>Sir Henry Irving and Miss Ellen Terry: A Record of Over Twenty Years at the Lyceum Theatre.</em>
    </a>
    London: Henry J. Drane, 1897. <em>Google Books</em>. Web. 6 Mar. 2014.
</p>
<p>
Campbell, Alfred Walter.    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?hl=en&amp;lr=&amp;id=LyU0nni_dAMC"><em>Histological Studies on the Localisation of Cerebral Function. </em></a>
    Cambridge: University Press, 1905. <em>Google Books</em>. Web. 9 Jun. 2014.
</p>
<p>
    Carpenter, William.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://archive.org/stream/principlesofment00carprich#page/n7/mode/2up">
        <em>Principles of Mental Physiology, with their Applications to The Training and Discipline of the </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://archive.org/stream/principlesofment00carprich#page/n7/mode/2up"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://archive.org/stream/principlesofment00carprich#page/n7/mode/2up"><em>Mind, and The Study of its Morbid Conditions.</em></a>
    London: Henry S. King and Co., 1875. <em>Internet Archive.</em> Web. 20 Sep. 2013.
</p>
<p>
    "Catalogue of Valuable Books, Autograph Letters, and Illuminated and Other Manuscripts, Including the Library of the Late Bram Stoker, Esq. And Associated
    Press (1913)." <em>The Lost Writings of Bram Stoker. </em>222-241<em> </em>Ed. John Edgar Browning. New York: Palgrave Macmillan, 2012. Print.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.museumoflondonprints.com/image/68598/john-galt-cats-meat-man-c-1901">"Cat's Meat Man."</a>
    <em>Museum of London</em>
    , 1901. Web. 4 May. 2014.
</p>
<p>
    Central Electric Company.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=0EIOAAAAYAAJ"><em> General Catalogue of Electrical Supplies.</em></a> Chicago: Central
    Electric Company, 1899. <em>Google Books.</em> Web. 30 Sep. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.visitlondon.com/discover-london/london-areas/central/city-of-london">"The City of London."</a>
    <em>London: Official Visitor Guide. </em>
    Web. 5 Jul. 2014.
</p>
<p>
Chaucer, Geoffrey. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://omacl.org/Troilus/"><em>Troilus and Criseyde.</em></a> Ed. Douglas B. Killings.    <em>The Online Medieval &amp; Classical Library,</em> Mar. 1995. Web. 3 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.ncbi.nlm.nih.gov/pmc/articles/PMC2263956/?page=1">
        "The Charge against Professor Ferrier under the Vivisection act: Dismissal of the summons."
    </a>
    <em>British Medical Journal</em>
    2.1090 (19 Nov. 1881): 836-842. <em>National Library of Medicine.</em> Web. 14 Sep. 2013.
</p>
<p>
    Chico, Beverley.
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=GdbYAQAAQBAJ&amp;pg=PA57#v=onepage&amp;q&amp;f=false">
            Hats and Headwear Around the World: A Cultural Encyclopedia.
        </a>
    </em>
    Santa Barbara: ABC-CLIO, 2013. <em>Google Books. </em>Web. 10 Jun. 2014. <span >[Preview Only]</span>
</p>
<p>
    Christian, Edmund B. V.. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=nKxBAAAAYAAJ">A Short History of Solicitors</a>.</em> London: Reeves &amp; Turner,
    1896. <em>Google Books.</em> Web. 24 Jun. 2013.
</p>
<p>
Clayes, S. L. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=DGLnAAAAMAAJ&amp;pg=PA49">"Two Famous Bats: Vampires and Kalongs."</a>    <em>The Popular Science News and Boston Journal of Chemistry </em>27.4 (Apr. 1893): 49-20. <em>Google Books.</em> Web. 27 Sep. 2013.
</p>
<p>
    Clerkin, Paul.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://archiseek.com/2009/1879-north-london-consumptive-hospital-hampstead-london/#.U5RSuCiiUnh">
        "1879 – North London Consumptive Hospital, Hampstead, London."
    </a>
    <em>Archiseek,</em>
    1996-2012. Web. 8 Jun. 2014.
</p>
<p>
Colville, Deborah. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.ucl.ac.uk/bloomsbury-project/institutions/uch.htm">"University College Hospital."</a>    <em>UCL Bloomsbury Project</em>, 13 Apr. 2011.<em> </em>Web. 8 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.lib.utexas.edu/maps/historical/central_europe_1460.jpg"><em>Central Europe 1460.</em></a>
    Map.<em> Perry-Castañeda Library Map Collection</em>. University of Texas Libraries.<em> </em>Web. 7 May. 2014.
</p>
<p>
Chalmers, Andres.    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=j8c_AAAAYAAJ">Transylvanian Recollections: Sketches of Hungarian Travel and History</a>.</em> London: Smart
    &amp; Allen, 1880. <em>Google Books.</em> Web. 19 Jun. 2013.
</p>
<p>
    Charlton, Lionel.
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=25dbAAAAQAAJ">
            The History of Whitby, and of Whitby Abbey: Collected from the Original Records of the Abbey,
        </a>
    </em>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=25dbAAAAQAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=25dbAAAAQAAJ">
        <em>and Other Authentic Memoirs, Never Before Made Public. Containing, Not Only the History of Whitby </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=25dbAAAAQAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=25dbAAAAQAAJ">
        <em>and the Country Adjacent, But Also the Original and Antiquity of Many Particular Families and Places in </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=25dbAAAAQAAJ"><em> </em></a>
    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=25dbAAAAQAAJ">Other Parts of Yorkshire, Divided Into Three Books.</a> </em>
    2 vols. York: A Ward, 1779. <em>Google Books.</em> Web. 24 Jul. 2013.
</p>
<p>
Clark, William Tierney.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=8UigAAAAMAAJ"><em>An Account, with Illustrations, of the Suspension Bridge Across the River Danube.</em></a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=8UigAAAAMAAJ"> </a> London: Bradbury &amp; Evans, 1852. <em>Google Books.</em> Web. 25 Jun. 2013.
</p>
<p>
    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.vatican.va/archive/ENG1104/__P52.HTM">Code of Canon Law.</a> Libreria Editrice Vaticana, 2003. </em>
    Web. 8 Jun. 2014
</p>
<p>
Coleridge, Samuel Taylor. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.poetryfoundation.org/poem/173253">"The Rime of the Ancient Mariner (text of 1834)."</a>    <em>Poetry Foundation. </em>Web. 1 Jun. 2014.
</p>
<p>
    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.collinsdictionary.com/dictionary/american">Collins American English Dictionary.</a> Collins,</em>
    2014.<em> </em>Web. 8 Jun. 2014.
</p>
<p>
Collins, Wilkie. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=UoJxTi-zohYC"><em>The Woman in White.</em></a> New York: Harper &amp; Brothers, 1865.    <em>Google Books</em>. Web. 8 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=l447AQAAMAAJ">"Concessions for Working Petroleum in Roumania."</a>
    <em>Board of Trade Journal of Tariff and Trade Notices and Miscellaneous Commercial Information </em>
    14 (Jan.- Jun. 1893): 570. <em>Google Books.</em> Web. 30 Oct. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.contemplator.com/history/grog.html">"The Contemplator's Short History of Grog."</a>
    <em>Contemplations from the Marianas Trench</em>
    .<em> </em>Web. 5 Jul. 2014.
</p>
<p>
    Cook, Thomas.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=m-_Ks_4KRHIC">
        <em>Cook's Continental Time Table, Steamship and Air Services Guide: A Cheap, Concise, and </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=m-_Ks_4KRHIC"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=m-_Ks_4KRHIC">
        <em>Simple Guide to All the Principal Lines of Railway, Steamers, Diligences, &amp;c., &amp;c., On the Continent of </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=m-_Ks_4KRHIC"><em> </em></a>
    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=m-_Ks_4KRHIC">Europe, with Especial Reference to Cook's Direct and Circular Tickets.</a> </em>
    Ed. James Bredall. London: Thos. Cook &amp; Son, 1892. <em>Google Books</em>. Web. 13 Sep. 2013.
</p>
<p>
    Cook, Thomas. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=fJ4HAAAAQAAJ">Cook's Handbook for London, with Two Maps</a>.</em> London: Thos. Cook &amp;
    Sons, 1885. <em>Google Books</em>. Web. 17 Oct. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://en.wikibooks.org/wiki/Cookbook:Feuerzangenbowle">"Cookbook: Feuerzangenbowle."</a>
    <em>Wikibooks, </em>
    15 Feb. 2002. Web. 9 Jun. 2014.
</p>
<p>
    <em>Coppola, Francis Ford, dir. </em>
    <em>Bram Stoker's Dracula</em>
    . Columbia, 1992. Film.
</p>
<p>
Cowper, Katie. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=0NgaAAAAYAAJ&amp;pg=PA65#v=onepage&amp;q&amp;f=false">"The Decline of Reserve Amoungst Women."</a>    <em>The Nineteenth Century </em>27 (Jan. - Jun. 1890): 65- 71. <em>Google Books.</em> Web. 29 Jun. 2013.
</p>
<p>
[Crawford, Marion.] <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=TEYuAAAAYAAJ&amp;pg=PA107#v=onepage&amp;q&amp;f=false">"A Mystery of the Campagna."</a>    <em>The Witching Time: Tales for the Year's End. </em>New York: D Appleton &amp; Co., 1887. 107-60. <em>Google Books</em>. Web. 30 Jun. 2014.
</p>
<p>
    Crosse, Andrew. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=swUcAAAAMAAJ"><em>Round About the Carpathians.</em></a> Edinburgh and London: William Blackwood
    and Sons, 1878. <em>Google Books</em>. Web. 14 Aug. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.nationalarchives.gov.uk/currency/">"Currency Converter."</a>
    <em>Great Britain National Archives.</em>
    Web. 6 May. 2014
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://www.google.com/finance/converter">"Currency Converter."</a>
    <em>Google.</em>
    Web. 7 Jun. 2014
</p>
<p>
    Curtis, Dan, dir. <em>Bram Stoker's Dracula.</em> Latglen Ltd, Feb. 1974. Television.
</p>
<p>
    Curtis, Gary. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.fallacyfiles.org/redherrf.html">"Red Herring."</a> <em>Fallacy Files,</em> 2013.<em> </em>Web. Jun 8 2014.
</p>
<p>
    Curtis, Gary. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.fallacyfiles.org/noncause.html">"Non Causa Pro Causa."</a> <em>Fallacy Files,</em> 2013.<em> </em>Web. Jun 8 2014.
</p>
<p>
    Curzon, George N. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=NDYbAAAAYAAJ">Problems of the Far East: Japan - Korea – China</a>,</em> Rev Ed.
    Westminster: Archibald Constable and Co., 1896. <em>Google Books</em>. Nov. 12 Aug. 2013.
</p>
<p>
D., F G. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=93cmAQAAIAAJ&amp;pg=PA89#v=onepage&amp;q&amp;f=false">"The Fakir of Lahore."</a>    <em>The Word, A Monthly Magazine Devoted to Philosophy, Science, Religion, Eastern Thought, Occultism, Theosophy, and the Brotherhood of Humanity </em>13
    (Apr. - Sep. 1911): 89-92. <em>Google Books</em>. Sep. 27 Aug. 2013.
</p>
<p>
D'Anvers, Caleb. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=kjAJAAAAQAAJ&amp;pg=PA120#v=onepage&amp;q&amp;f=false">"Political Vampyres."</a>    <em>The Craftsman </em>13 (1737): 120-9. <em>Google Books.</em> Web. 18 May. 2014.
</p>
<p>
    Dagi, T. Forscht. "The Management of Head Trauma." <em>A History of Neurosurgery: In Its Scientific and Professional Contexts.</em> Ed. Samuel H.
    Greenblatt. Park Risge: The American Association of Neurological Surgeons, 1997. 289-344. Print.
</p>
<p>
    Dahn, Felix.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=9rDPAAAAMAAJ&amp;pg=PA22#v=onepage&amp;q&amp;f=false">"The Vampire."</a> <em>German Lyrics. </em>
    Henry Phillips. Trans. Philadelphia: Macalla &amp; Company, 1892. 22-3. <em>Google Books</em>. Web. 28 Jun. 2014.
</p>
<p>
Dalby, Andrew. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=FrWgDRkS90EC&amp;pg=PA67#v=onepage&amp;q&amp;f=false">"Transyvanian Inns and Travellers."</a>    <em>Oxford Symposium on Food and Cookery </em>1991, Harlan Walker Ed. Prospect Books, 1991. 67-75. <em>Google Books</em>. Web. 14 Sep. 2013.
</p>
<p>
    Dalby, Richard. "Friends of Bram Stoker 5 – Hall Caine." <em>The Bram Stoker Society Journal </em>11 (1999): 28-32. Print.
</p>
<p>
    Dalby, Richard. "Makt Myrkranna – Powers of Darkness." <em>The Bram Stoker Society Journal</em> 5 (1993): 2-3. Print.
</p>
<p>
Daniels, George.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=O6QyAAAAIAAJ"><em>A Compendium of Commission Cases: Being a Collection of Cases on the Law Relating to </em></a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=O6QyAAAAIAAJ"><em> </em></a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=O6QyAAAAIAAJ"><em>Auctioneers' and Estate Agents' Commission.</em></a> 3rd ed. London: Frank P. Wilson, 1900.    <em>Google Books</em>. Web. 18 Oct. 2013.
</p>
<p>
    Dasent, Arthur Irwin.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=N0UuAAAAMAAJ">
        <em><u>The History of St. James's Square and the Foundation of the West End of London with a </u></em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=N0UuAAAAMAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=N0UuAAAAMAAJ"><em><u>Glipse of Whitehall in the Reign of Charles the Second.</u></em></a>
    <u> </u>
    London: Macmillan and Co., 1895. <em>Google Books</em>. Web. 4 Oct. 2013.
</p>
<p>
    Davies, Bernard. "Bram Stoker's Translyvania – A Critical Reassessment." <em>The Bram Stoker Society Journal </em>10 (1998): 3-16. Print.
</p>
<p>
    Davies, Bernard. "Inspirations, Imitations and In-Jokes in Stoker’s Dracula." <em>Dracula: A Documentary Volume.</em> Ed. Elizabeth Miller. New York:
    Pegasus Books, 2009. 223-6. Print.
</p>
<p>
    Davies, Bernard. "Unearthing Dracula – Burying Stoker." <em>The Bram Stoker Society Journal </em>11 (1999): 38-45 Print.
</p>
<p>
    Dean, Frank. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.google.com/patents/US27621">"US 27621 A."</a> <em>Google Patents. </em>Web. 10 Jun. 2014.
</p>
<p>
    Deane, Hamilton and John L. Balderston. <em>Dracula: The Vampire Play in Three Acts. </em>London, Samuel French Inc., 1927. Print.
</p>
<p>
    Debrett, John. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=iwtVse6yazQC">Debrett's Peerage of England, Scotland, and Ireland.</a> </em>2 vols. London: G.
    Woodfall, 1828. <em>Google Books</em>. Web. 4 Oct. 2013.
</p>
<p>
De Groot, Jan Jakob Maria.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=S7tZAAAAMAAJ"><em>The Religious System of China: Its Ancient Forms, Evolution, History and Present </em></a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=S7tZAAAAMAAJ"><em> </em></a>    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=S7tZAAAAMAAJ">Aspect, Manners, Customs and Social Institutions Connected Therewith.</a> </em> Leyden: E. J.
    Brill, 1892. <em>Google Books.</em> Web. 3 Oct. 2013.
</p>
<p>
    De Roos, Hans Corneel.
    <a href="https://web.archive.org/web/20180228143056mp_/http://www.google.com/url?sa=t&amp;rct=j&amp;q=&amp;esrc=s&amp;source=web&amp;cd=1&amp;cad=rja&amp;uact=8&amp;ved=0CCAQFjAA&amp;url=http://www.mysterious-journeys.com/pdf/letter_easter_2014.pdf&amp;ei=SrL7U9W1HMW1iwKq6YHoBQ&amp;usg=AFQjCNHHu-u4xzjudoaXii65bPht1t2-gw&amp;sig2=A6btIp3xJHie7tKYzkp8og&amp;bvm=bv.73612305,d.cGE">
        "Bram Stoker's Original Preface to Dracula Revealed?"
    </a>
    <em>Letter from Castle Dracula: Official News Bulletin of the Transylvanian Society of Dracula, </em>
    <em>18 Apr. 2014. Web. 25 Aug. 2014.</em>
</p>
<p>
De Roos, Hans Corneel.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.ep.liu.se/ea/cis/2012/002/cis15002.pdf">"Bram Stoker's Vampire Trap: Vlad the Impaler and his Nameless Double."</a>    <em>Linköping Electronic Articles in Computer and Information Science. </em>Linköping University Electronic Press, 2012. Web. 28 Oct. 2013.
</p>
<p>
De Roos, Hans Corneel.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.ep.liu.se/ea/cis/2012/001/cis14001.pdf">"Castle Dracula: Its Exact Location Reconstructed from Stoker's Novel, His Research </a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.ep.liu.se/ea/cis/2012/001/cis14001.pdf"> </a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.ep.liu.se/ea/cis/2012/001/cis14001.pdf">Notes and Contemporary Maps."</a>    <em>Linköping Electronic Articles in Computer and Information Science. </em>Linköping University Electronic Press, 2012. Web. 28 Oct. 2013.
</p>
<p>
De Roos, Hans Corneel.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.mysterious-journeys.com/pdf/letter-december.pdf">"Fact and Fiction in Stoker's 'Dracula' – Time for a New Paradigm."</a>    <em>Letter from Castle Dracula: Official News Bulletin of the Transylvanian Society of Dracula, </em><em>Dec. 2013. Web. 25 Aug. 2014.</em>
</p>
<p>
De Roos, Hans Corneel.<em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.mysterious-journeys.com/pdf/letter-feb-2014.pdf">"Makt Myrkranna: Mother of All Dracula Modifications?"</a></em><em> </em>    <em>Letter from Castle Dracula: Official News Bulletin of the Transylvanian Society of Dracula, </em><em>3 Feb. 2014. Web. 25 Aug. 2014.</em>
</p>
<p>
Dickens, Charles. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=0dwNAAAAQAAJ&amp;pg=PA250#v=onepage&amp;q&amp;f=false">"Fallen from the Clouds."</a>    <em>All the Year Round </em>8 (22 Nov. 1862): 250-6. <em>Google Books. </em>Web. 27 Sep. 2013.
</p>
<p>
Dickens, Charles.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=dSRLAAAAYAA"> </a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=dSRLAAAAYAA"><em>Our Mutual Friend.</em></a> 2 vols. London: Chapman &amp; Hall, 1911. <em>Google Books.</em>
    Web. 26 Sep. 2013.
</p>
<p>
    Dickens, Charles. Jr. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=sTMWAAAAYAAJ"><em>Dictionary of London: An Unconventional Handbook</em></a>. London:
    Charles Dickens, 1879. <em>Google Books.</em> Web. 4 Oct. 2013.
</p>
<p>
Dickens, Charles. Jr.    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=GDMWAAAAYAAJ">Dictionary of the Thames, from Oxford to the None: An Unconventional Handbook</a>. </em>London:
    J. Smith, 1894. <em>Google Books.</em> Web. 25 Jun. 2013.
</p>
<p>
    Dickens, David B. "The German Matrix of Stoker's Dracula." <em>Dracula: The Shade and the Shadow.</em> Kindle ed. Ed. Elizabeth Miller. Westcliff-on-Sea,
    Essex: Desert Island Books, 1997. File.
</p>
<p>
Diniejko, Andrzej.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.victorianweb.org/religion/sa1.html">"The Origin and Early Development of the Salvation Army in Victorian England."</a>    <em>Victorian Web, </em>11 Apr. 2013. Web. 27 Jul. 2014.
</p>
<p>
    [Disraeli, Benjamin.] The Earl of Beaconsfield. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=tWBLAAAAIAAJ">Henrietta Temple: A Love Story</a>.</em>
    London: Longman, Greens &amp; Co., 1837. <em>Google Books.</em> Web. 24 Aug. 2013.
</p>
<p>
Donnelly, Ignatius.    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=fS5DAAAAYAAJ&amp;pg=PA7#v=onepage&amp;q&amp;f=false"><em>The American People's Money.</em></a> Chicago: Laird
    &amp; Lee: 1895. <em>Google Books.</em> Web. 7 Nov. 2013.
</p>
<p>
    Dorman, Rushton.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=O8UWAAAAYAAJ">
        <em>The Origin of Primitive Superstitions and Their Development Into the Worship of Spirits and </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=O8UWAAAAYAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=O8UWAAAAYAAJ"><em>the Doctrine of Spiritual Agency Among the Aborigines of America.</em></a>
    Philadelphia: J. B. Lippincoot &amp; Co., 1881. <em>Google Books</em>. Web. 17 Aug. 2013.
</p>
<p>
    Dromgoole, Patrick, dir. "Dracula." <em>Mystery and Imagination.</em> Thames Television. 18 Nov. 1968. Television.
</p>
<p>
Druitt, Robert.    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=nZIBAAAAQAAJ">Report on the Cheap Wines from France, Italy, Austria, Greece and Hungary</a>.</em> 2nd rev.
    ed. London: Cavill, Edwards and Co., 1873. <em>Google Books.</em> Web. 19 Jun. 2013
</p>
<p>
Dudley, Emilius Clark.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=qlLZ4N261hcC"><em>Diseases of Women: A Treatise on the Principles and Practice of Gynecology, for </em></a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=qlLZ4N261hcC"><em> </em></a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=qlLZ4N261hcC"><em>Students and Practitioners.</em></a> Philadelphia: Lea Brothers &amp; Co., 1899. Web. 10 Mar.
    2013.
</p>
<p>
    Dumas, Alexandre. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.cadytech.com/dumas/stories/vampire.php">The Vampire.</a> </em>Trans. Frank Morlock. <em>Cadytech,</em> 1999. Web.
    30 Jun. 2014.
</p>
<p>
    Dumas, Alexandre. "The Vampire of the Carpathian Mountains" <em>The Best Vampire Stories 1800-1849: A Classic Vampire Anthology.</em> eBook ed. Ed. Andrew
    Barger. Bottletree Books LLC, 2012. File.
</p>
<p>
    Eagles, Bill, dir. <em>Dracula</em>. BBC. 28 Dec. 2006. Television.
</p>
<p>
Ebor, Arthur. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=h1EYAQAAIAAJ&amp;pg=PA242&amp;#v=onepage&amp;q&amp;f=false">"Henry Jenkins."</a>    <em> The Mirror of Literature, Amusement, and Instruction </em>14 (1829): 242-243. <em>Google Books. </em>Web. 27 Sep. 2013.
</p>
<p>
    Egerton, Wilbraham.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=slUOAAAAQAAJ">
        <em>An Illustrated Handbook of Indian Arms: Being a Classified and Descriptive Catalogue of </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=slUOAAAAQAAJ"><em> </em></a>
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=slUOAAAAQAAJ">
            the Arms Exhibited at the India Museum: With An Introductory Sketch of the Military History of India.
        </a>
    </em>
    London: William H. Allen, 1880. <em>Google Books. </em>Web. 17 Oct. 2013.
</p>
<p>
Eighteen-Bisang, Robert. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.casebook.org/dissertations/rip-thirst.html">"Dracula, Jack the Ripper and A Thirst for Blood."</a>    <em>Ripperologist </em>60 (Jul. 2005). <em> Casebook.org.</em> Web. 22 May. 2014.
</p>
<p>
Ellicott, John <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=DxUbAAAAYAAJ&amp;pg=PA679#v=onepage&amp;q&amp;f=false">"What is Told By The Bell."</a>    <em>St. Nicholas </em>24.2 (May – Oct. 1897): 679-81. <em>Google Books.</em> Web. 13 Aug. 2013.
</p>
<p>
Ellis, Harold.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.bjhm.co.uk/cgi-bin/go.pl/library/article.cgi?uid=24500;article=hm_68_8_447">"James Blundell, Pioneer of Blood Transfusion."</a>    <em>British Journal of Hospital Medicine </em>68.8 (Aug. 2007): 447. Web. 7 Sep. 2013.
</p>
<p>
    Elmes, James.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=8I4wAQAAMAAJ">
        <em>A Topographical Dictionary of London and Its Environs: Containing descriptive and Critical </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=8I4wAQAAMAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=8I4wAQAAMAAJ">
        <em>Accounts of All the Public and Private Buildings, Offices, Docks, Squares, Streets, Lanes, Wards, </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=8I4wAQAAMAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=8I4wAQAAMAAJ">
        <em>Liberties, Charitable Institutions, Commercial, Charitable, Scholastic and Other Establishments, with List </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=8I4wAQAAMAAJ"><em> </em></a>
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=8I4wAQAAMAAJ">
            of their Officers, Patrons, Incombents of Livings, &amp;c. &amp;c. &amp; in the British Metropolis.
        </a>
    </em>
    London: Wittaker, Treacher and Arnot, 1831. <em>Google Books. </em>Web. 20 Oct. 2013.
</p>
<p>
Elworthy, Frederick Thomas.    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=yIdAAAAAYAAJ">The Evil Eye: An Account of this Ancient and Widespread Superstition</a>. </em>London: John
    Murray, 1895. <em>Google Books. </em>Web. 19 Jun. 2013.
</p>
<p><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://en.wikisource.org/wiki/1911_Encyclopædia_Britannica"><em>Encyclopædia Britannica</em></a>. 11th ed. 29 vols. Cambridge: University Press, 1911. <em>Internet Archive.</em> Web. 4 May. 2014.</p>
<p>
    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.britannica.com/">Encyclopædia Britannica On-line.</a> </em>
    Web. 4 May. 2014.
</p>
<p>
Emerson, Edward Randolph.<em> <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=qjYPAAAAYAAJ">Beverages, Past and Present: An Historical Sketch of their Production, together </a></em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=qjYPAAAAYAAJ"><em> </em></a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=qjYPAAAAYAAJ"><em>with a Study of the Customs Connected with their Use</em></a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=qjYPAAAAYAAJ">.</a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=qjYPAAAAYAAJ"> </a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=qjYPAAAAYAAJ">2</a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=qjYPAAAAYAAJ"> </a>vols. New York and London: G. P.
    Putnam's Sons, 1908. <em>Google Books.</em> Web. 19 Jun. 2013.
</p>

<p>Emerson, Oliver. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=rHY4AQAAIAAJ">The Earliest English Translations of Bürger's Lenore: A Study in English and German Romanticism</a></em>. Cleveland: The Western Reserve University Press, 1915. <em>Google Books.</em> Web. 23 Jun. 2013.</p>

<p><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.parliament.uk/about/faqs/house-of-commons-faqs/budget/">"FAQ Budget."</a> <em>Great Britain Parliament.</em> Web. 4 May. 2014.</p>

<p><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://tidesandcurrents.noaa.gov/faq4.html">"FAQ - Tidal Current Predictions and Data."</a> <em>National Oceanic and Atmospheric Administration.</em> Web. 2 Jun. 2014.</p>
<p>
    Farson, Daniel. <em>The Man Who Wrote Dracula.</em> London: Michael Joseph, 1975. Print.
</p>
<p>
Ferrier, David. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/stream/philtrans02765954/02765954#page/n0/mode/2up">"Experiments on the Brain of Monkeys. – No. I."</a>    <em>Proceedings of the Royal Society of London </em>1 (Jan. 1874): 400-30. <em>Internet Archive.</em> Web. 9 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.aim25.ac.uk/cgi-bin/search2?coll_id=7194&amp;inst_id=8">"Ferrier, Sir David (1843-1928)."</a>
    <em>Archives in London and the M25 Area, </em>
    Nov. 2013. Web. 31 May 2014.
</p>
<p>
    Figuier, Louis.
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=6GFpAAAAIAAJ">The Insect World</a>
        , Being A Popular Account of the Orders of Insects, Together with a Description of The Habits and Economy of Some of the Most Interesting Species.
    </em>
    New York: D. Appleton &amp; Co, 1868. <em>Google Books.</em> Web. 10 Oct. 2013.
</p>
<p>
    Fine. John V. A. "In Defense of Vampires." <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://openlibrary.org/works/OL15840793W/The_vampire">The Vampire: A Casebook</a>,</em> Ed. Allen
    Dundes. Madison: The University of Wisconsin Press, 1998. 35-46. <em>OpenLibrary.</em> Web. 19 Jun. 2013.
</p>
<p>
    Fisher, Terrence, dir. <em>The Horror of Dracula.</em> Hammer Films, 1958. Film.
</p>
<p align="LEFT">
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://commons.wikimedia.org/wiki/Flags_of_Romania">"Flags of Romania."</a>
    <em>Wikimedia Commons.</em>
    Web. 10 Jun. 2014.
</p>
<p>
    Flint, Austin. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=vi9AAAAAYAAJ">A Text-Book of Human Physiology.</a> </em>4th ed. New York: Appleton and
    Company, 1896. <em>Google Books.</em> Web. 5 Oct. 2014.
</p>
<p align="LEFT">
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://195.8.196.34/~thelordj/braminvitam.co.uk/forgotton-tomb-of-dracula.shtml">"The Forgotten Tomb of Dracula."</a>
    <em>Bram in Vitam. </em>
    Web. 8 Jun. 2014
</p>
<p>
    Foreman, Henry James. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=67gKAAAAYAAJ"><em>London, an Intimate Picture.</em></a> New York: McBride, Nast &amp;
    Company, 1913. <em>Google Books.</em> Web. 24 Oct. 2013
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=aswXAQAAMAAJ&amp;pg=PA842#v=onepage&amp;q&amp;f=false">"Four Devices of the English Burglar."</a>
    <em>Popular Mechanics </em>
    10.12 (Dec. 1908): 842-843. <em>Google Books.</em> Web. 24 Oct. 2013.
</p>

<p>"Four Roses." <em>Dracula</em>. NBC. 17 Jan. 2014. Television.</p>

<p>
    Fowler, Nathaniel Clark. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=-JA0AAAAMAAJ"><em>1000 Things Worth Knowing</em></a>. New York: Sully and Kleintech,
    1913. <em>Google Books.</em> Web. 10 Mar. 2014.
</p>
<p>
Fox, F.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://archive.org/stream/minutesproceedi27britgoog#page/n318/mode/2up">"Viaduct over the River Esk at Whitby and Culverts in the Ravines."</a>    <em> Minutes of the Proceedings of the Institution of Civil Engineers. </em>Vol. 86. Ed. James Forrest. London: The Institution of Civil Engineers, 1886.
    303-311. <em>Internet Archive.</em> Web. 24 Jul. 2013.
</p>
<p>
    <a name="mlaarticle"></a>
    <a name="mlawork"></a>
    <a name="mlavolume"></a>
    <a name="mlapublisher"></a>
    <a name="mlayear"></a>
    Fox, William. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.newadvent.org/cathen/11431a.htm.%20">"Luigi Palmieri."</a> <em>The Catholic Encyclopedia</em>. Vol. 11. New York: Robert
    Appleton Company, 1911. Web. 8 Jun. 2014
</p>
<p>
    Franco, Jess, dir. <em>Count Dracula.</em> American-International, 1970. Film.
</p>
<p>
    Frayling, Christopher. "Mr. Stoker's Holiday." <em>Bram Stoker: Centenary Essays</em>. Ed. Jarlath Killeen. Dublin: Four Courts Press, 2014: 179-200.
    Print.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.thefreedictionary.com/"><em>The Free Dictionary.</em></a>
    <em>Farlex. </em>
    Web. 4 May. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://idioms.thefreedictionary.com/"><em>The Free Dictionary</em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://idioms.thefreedictionary.com/"> (Idioms).</a>
    <em>Farlex. </em>
    Web. 8 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://legal-dictionary.thefreedictionary.com/"><em>The Free Dictionary</em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://legal-dictionary.thefreedictionary.com/"> (Legal).</a>
    <em>Farlex. </em>
    Web. 8 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://medical-dictionary.thefreedictionary.com/"><em>The Free Dictionary</em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://medical-dictionary.thefreedictionary.com/"> (Medical).</a>
    <em>Farlex. </em>
    Web. 8 Jun. 2014.
</p>
<p>
    Fry, Danby P.
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=Yfo1AQAAMAAJ">Lunacy Law: The Statutes Relating to Private Lunatics, Pauper Lunatics, Criminal Lunatics, </a>
    </em>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=Yfo1AQAAMAAJ"><em> </em></a>
    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=Yfo1AQAAMAAJ">Commissions of Lunacy, Public and Private Asylums, and the Commissioners in Lunacy.</a> </em>
    3rd ed. London, Knight &amp; Co., 1890. <em>Google Books. </em>Web. 5 Jun. 2014.
</p>
<p>
Fry, Herbert.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=jctAAAAAYAAJ"> </a>    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=jctAAAAAYAAJ">London in 1885: Illustrated by Eighteen Bird's-eye Views of the Principal Streets.</a> </em>
    London: W. H. Allen &amp; Co., 1885. <em>Google Books.</em> Web. 27 Oct. 2013.
</p>
<p>
Gautier, Theophile. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=xLk4AQAAMAAJ">Clarimonde</a>.</em> Trans. Lafcadio Hearn. New York: Brentano's, 1899.    <em>Google Books.</em> Web. 6 Mar. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=6VgmAQAAIAAJ&amp;pg=PA238#v=onepage&amp;q&amp;f=false">"General Notes."</a>
    <em>Journal of the Society of Arts </em>
    44 (31 Jan. 1896): 238. <em>Google Books.</em> Web. 22 Sep. 2013.
</p>
<p>
    Gerard, Emily. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=UJcUAAAAYAA"><em>The Land Beyond the Forest: Facts, Figures and Fancies from Transylvania</em></a>
    . 2 vols. Edinburgh: William Blackwood and Sons, 1888. <em>Google Books.</em> Web. 29 Jun. 2013.
</p>
<p>
Gerard, Emily. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=ZvpXAAAAIAAJ&amp;pg=PA130#v=onepage&amp;q&amp;f=false">"Transylvanian Superstitions."</a>    <em>The Nineteenth Century </em>18 (Jul. - Dec. 1885): 130-150. <em>Google Books.</em> Web. 19 Jun. 2013.
</p>
<p>
    Gibbons, Edward.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=q1iV7q6vqr0C">
        <em>The History of the Decline and Fall of the Roman Empire: For the Use of Families and Young. </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=q1iV7q6vqr0C"><em> </em></a>
    3 vols. Ed. Thomas Bowdler. London: Longman, Rees, Orme, Brown and Green, 1826. <em>Google Books.</em> Web. 29 Jun. 2013.
</p>
<p>
    Gilbert, William. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://gaslight.mtroyal.ab.ca/lastlord.htm">"The Last Lords of Gardonal."</a> <em>Gaslight</em>. Web. 6 Mar. 2014.
</p>
<p>
Gilman, Daniel Coit et al. eds. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=ex4VAAAAYAAJ"><em>The New International Encyclopædeia</em></a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=ex4VAAAAYAAJ">.</a> New York: Dood, Mead and Company, 1909. <em>Google Books.</em> Web. 7 Sep. 2013.
</p>
<p>
    <a><em>Google Maps.</em></a>
    Map. <em>Google.</em> Web. 10 Jun 2014.
</p>
<p>
    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://translate.google.com/">Google Translate.</a> Google.</em>
    Web. 10 Jun. 2014.
</p>
<p>
    Gordon, William John. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=5t8JAAAAMAAJ">The Horse World of London.</a> </em>London: The Religious Tract Society,
    1893. <em>Google Books.</em> Web. 17 Aug. 2013.
</p>
<p>
Gould, Augustus Addison, Ed.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=sigIAQAAIAAJ"><em>A System of Natural History Containing Scientific and Popular Descriptions of </em></a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=sigIAQAAIAAJ"><em> </em></a>    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=sigIAQAAIAAJ">Man, Quadrupeds, Birds, Fishes, Reptiles and Insects.</a> </em>Brattleboro: Fessenden &amp;
    Co., 1834. <em>Google Books.</em> Web. 27 Sep. 2013.
</p>
<p>
Gould, George Milbry.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=WAa6AAAAIAAJ"><em>A Dictionary of New Medical Terms: Including Upwards of 38,000 Words and Many Useful </em></a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=WAa6AAAAIAAJ"><em> </em></a>
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=WAa6AAAAIAAJ">
            Tables, Being a Supplement to "An Illustrated Dictionary of Medicine Biology, and Allied Sciences.
        </a>
    </em>
    Philadelphia: P. Blakiston's Son &amp; Co., 1905. <em>Google Books.</em> Web. 24 Oct. 2013.
</p>
<p>
    Gould, George M. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=vlZQAQAAIAAJ"><em>An Illustrated Dictionary of Medicine, Biology and Allied Sciences.</em></a>
    Philadelphia: Bliston, Son &amp; Co., 1898. <em>Google Books.</em> Web. 27 Sep. 2013.
</p>
<p>
    Gray, Robert.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=zJ40AAAAMAAJ">
        <em>The Theory of Dreams: In Which An Inquiry is Made Into The Powers and Faculties of the Human </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=zJ40AAAAMAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=zJ40AAAAMAAJ">
        <em>Mind, As They are Illustrated in the Most Remarkable Dreams Recorded in Sacred and Profane History.</em>
    </a>
    2 vols. London: F. C. and J. Rivington, 1808. <em>Google Books.</em> Web. 17 Aug. 2013.
</p>
<p>
    Green, Nick and Liz Mellish. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://eliznik.org.uk/">Eliznik Web Pages</a>,</em> 2014. Web. 19 Jun. 2013.
</p>
<p>
    Green, Richard. Ed. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=jXYWAAAAYAAJ"><em>The International Cyclopedia: A Compendium of Human Knowledge.</em></a> 15
    vols. New York: Dodd, Mead and Company, 1890. <em>Google Books.</em> Web. 8 May. 2013.
</p>
<p>
    Greenblatt, Stephen. <em>Hamlet in Purgatory.</em> Princeton: Princeton University Press, 2001. Print.
</p>
<p>
    Grinling, Charles Herbert. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=168pAAAAYAA"><em>The History of the Great Northern Railway: 1845-1902</em></a>.
    London: Methuen &amp; Co, 1903. <em>Google Books.</em> Web. 17 Aug. 2013.
</p>
<p>
Grout, James. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://penelope.uchicago.edu/~grout/encyclopaedia_romana/aconite/aconite.html">"Aconite Poisoning."</a>    <em>Encyclopedia Romana,</em> 27 Jun. 2014. Web. 13 Jul. 2014.
</p>
<p>
Grumeza, Ion.    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?hl=en&amp;lr=&amp;id=1kOMSQnGm_cC">Dacia: Land of Transylvania, Cornerstone of Ancient Eastern Europe.</a> </em>
    Lanham: Hamilton Books, 2009. <em>Google Books.</em> Web. 10 Jun. 2014. <span >[Preview Only]</span>
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.army.mod.uk/infantry/regiments/30364.aspx">"The Gurkha Culture."</a>
    British Army, 2014. Web. 9 Jun. 2014.
</p>
<p>
    Haining, Peter. <em>The Dracula Centenary Book.</em> London: Souvenir Press Ltd., 1987. Print.
</p>
<p>
    Hall, Leslie. <em>Sex, Gender and Social Change in Britain Since 1880.</em> New York: St. Martin's Press, 2000. Print.
</p>
<p>
    Hallam, Henry. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=b54CAAAAYAAJ"><em>History of Europe During the Middle Ages</em></a>. 3 vols. New York: The
    Colonial Press, 1899. <em>Google Books.</em> Web. 29 Jun. 2013.
</p>
<p>
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=BmHFyE4QsywC">Handbook for the Instruction of Attendants on the Insane.</a>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=BmHFyE4QsywC"> </a>
    </em>
    London: Bailliere, Tindall, &amp; Cox, 1885. <em>Google Books.</em> Web. 10 Mar. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=MpsVAAAAYAA"><em>Handbook for Attendants on the Insane</em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=MpsVAAAAYAA">.</a>
    5th ed. Chicago: W. T. Keener &amp; Co., 1909. <em>Google Books.</em> Web. 10 Mar. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=1JEDAAAAQAAJ"><em>Hand-Book of Etiquette, Being a Complete Guide to the Usages of Polite Society.</em></a>
    London: Cassell, Petter and Galpin, 1860. <em>Google Books.</em> Web. 20 Sep. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=U2MZAAAAYAAJ"><em>Handbook of the Law Society.</em></a>
    London: Council of the Law Society, 1905. <em>Google Books.</em> Web. 22 Sep. 2013.
</p>
<p>
    Hardwicke, Herbert Junius. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=3zfRgTKWClcC"><em>Medical Education and Practice in All Parts of the World.</em></a>
    Philadelphia: Presley Bakiston, 1880. <em>Google Books.</em> Web. 7 Mar. 2014.
</p>
<p>
    Harper, David. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.etymonline.com/index.php"><em>Online Etymology Dictionary.</em></a> 2014. Web. 9 Jun. 2014.
</p>
<p>
    Hart, H. G. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=_KUaAAAAMAAJ">Hart's Annual Army List, Militia List, and Imperial Yeomanry List.</a> </em>London:
    John Murray, 1893. <em>Google Books.</em> Web. 8 Mar. 2014.
</p>
<p>
    Hatton, Joseph. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=Rq4-AAAAYAAJ"><em>The Life and Work of Alfred Gilbert, R.A., M.V.O., LL.D.</em></a> London: "The
    Art Journal" Office, 1903. <em>Google Books.</em> Web. 18 Oct. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=NkMxAQAAMAAJ&amp;pg=PA27#v=onepage&amp;q&amp;f=false">"Have Women Degraded Journalism?"</a>
    <em>The Literary Digest </em>
    13 (May – Oct. 1896): 27. <em>Google Books.</em> Web. 29 Jun. 2013.
</p>
<p>
Heilprin, Angelo and Louis Heilprin.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=N2tvuRaSPzAC"><em>Geographical Dictionary Of The World In The Early 20th Century With </em></a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=N2tvuRaSPzAC"><em> </em></a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=N2tvuRaSPzAC"><em>Pronouncing Gazetter.</em></a> Vol 1. New Delhi: Logo Press, 1990. <em>Google Books</em>. Web.
    10 Jun. 2014. <span >[Preview Only]</span>
</p>
<p>
    Hermans, Felicia Dorothea. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://digital.library.upenn.edu/women/hemans/works/hf-burning.html">"Casabianca."</a> University of Pennsylvania
    Libraries. Web. 1 Jun. 2014.
</p>
<p>
Hertslet, Edward, Ed.    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=4toNAAAAQAAJ">The Foreign Office List, Forming a Complete British Diplomatic and Consular Handbook,</a> </em>
    50th Ed. London: Harrison, 1877. <em>Google Books.</em> Web. 30 Oct. 2013.
</p>
<p>
    Herzog, Werner, dir. <em>Nosferatu the Vampyre. </em>Werner Herzog Filmproduktion. 1979. Film.
</p>
<p>
Heslop, Oliver.    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=Fhk_AAAAYAAJ"><em>Glossary of Words used in the Country of Northumberland and on the Tyneside.</em></a> London:
    Kegan Paul, Trench Trübner &amp; Co., 1892. <em>Google Books.</em> Web. 20 Sep. 2013.
</p>
<p>
Higgins, Anthony K. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.geus.dk/publications/bull/nr21/nr21_p117-368.pdf">"Catalogue of place names in northern East Greenland."</a>    <em>Geological Survey of Denmark and Greenland Bulletin </em>21 (2010): 117-368. Web. 30 May 2014.
</p>
<p>
    <em>Historical Directories. </em>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://cdm16445.contentdm.oclc.org/cdm/search/collection/p16445coll4/searchterm/greater%20london/field/region/mode/exact/conn/and/order/nosort">
        "Greater London."
    </a>
    University of Leicester's Special Collections Online. Web. 6 May. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://alabamamaps.ua.edu/historicalmaps/europe/europe_1876-1890.html"><em>Historical Maps of Europe: 1876-1890</em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://alabamamaps.ua.edu/historicalmaps/europe/europe_1876-1890.html">.</a>
    Map. <em>Alabama Maps. </em>Cartographic Research Laboratory, 7 Apr. 2014. <em> </em>Web. 7 May 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://archived.parapsych.org/historical_terms.html">"Historical Terms Glossary."</a>
    <em>Parapsychological Association,</em>
    1997-2009. Web 8 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://highgatecemetery.org/about/history">"History."</a>
    <em>Friends of Highgate Cemetary Trust, </em>
    2014. Web. 8 Jun. 2014
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.ideal-homes.org.uk/southwark/assets/histories/walworth">"History of Walworth."</a>
    <em>Ideal Homes.</em>
    Web. 8 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://albumacademicum.uva.nl/cgi/b/bib/bib-idx?c=ap;lang=en;tpl=history-en.tpl">
        "History of the Athenaeum Illustre and the Universiteit van Amsterdam."
    </a>
    University of Amsterdam. Web. 10 May. 2014.
</p>
<p>
    Hogg, Anthony. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://thevampirologist.wordpress.com/2014/04/06/bats-before-bram/">"Bats Before Bram."</a> <em>The Vampireologist,</em> 6 Apr.
    2014. Web. 24 Jun. 2014.
</p>
<p>
    Hogg, Anthony. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://doaav.blogspot.com/2011/02/unearthing-nosferatu.html">"Unearthing Nosferatu."</a> <em>The Vampireologist, </em>28 Feb. 2011.
    Web. 5 Aug. 2014.
</p>
<p>
    Holweck, Frederick. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.newadvent.org/cathen/04738b.htm">"De Profundis."</a> <em>The Catholic Encyclopedia.</em> Vol. 4. New York: Robert
    Appleton Company, 1908. Web. 8 Jun. 2014.
</p>
<p>
    Hoke, Donald.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.thebhc.org/publications/BEHprint/v008/p0076-p0088.pdf">
        "The Woman and the Typewriter: A Case Study in Technological Innovation and Social Change."
    </a>
    <em>Business and Economic History </em>
    2 (1979): 76-88. Web. 20 Nov. 2013.
</p>
<p>
Homer. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=5TNWAAAAcAAJ"><em>The Odyssey of Homer.</em></a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=5TNWAAAAcAAJ"><em> </em></a>Trans. Alexander Pope. London: Suttaby, Evanee &amp; Co, 1811. G<em>oogle Books.</em>
    Web. 7 Sep. 2013.
</p>
<p>
    Hood, Thomas. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=zQoZAAAAYAAJ"><em>The Poetical Works of Thomas Hood.</em></a> Ed. William Michael Rossetti. New
    York: George Routledge &amp; Sons, 1874. <em>Google Books.</em> Web. 30 Sep. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=AVkJAAAAIAAJ"><em>Horne's Guide to Whitby, Profusely Illustrated</em></a>
    . Whitby: Horne &amp; Son, 1897. <em>Google Books.</em> 13 Aug. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://palacehotelsoftheworld.com/hotelvierjahreszeitenmunich/">"Hotel Vier Jahreszeiten München – Four Seasons Munich."</a>
    <em>Palace Hotels of the World.</em>
    Web. 10 Jun. 2014.
</p>
<p>
    Howard, Contance Eleanora. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=m4gIAAAAQAAJ">Etiquette: What to Do, and How to Do It</a>.</em> London: F. V.
    White &amp; Co., 1885. <em>Google Books.</em> 24 Jun. 2013.
</p>
<p>
    Hulbert, Charles.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=XDJAAAAAYAAJ">
        <em>The Parlour Book of British Scenery, Architecture and Antiquities; Being A series of select </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=XDJAAAAAYAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=XDJAAAAAYAAJ">
        <em>Descriptions of Towns, Villages, Public Buildings, Gentlemen's Seats, Castles, Colleges, Churches, </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=XDJAAAAAYAAJ"><em> </em></a>
    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=XDJAAAAAYAAJ">Ruins, &amp;c, &amp;c, in Great Britain.</a> </em>
    2nd ed. Shrewsbury: Charles Hulbert, 1832. <em>Google Books.</em> Web. 26 Sep. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=l_kDAAAAQAAJ&amp;pg=PA114#v=onepage&amp;q&amp;f=false">"Human Hibernation -- The Fakir."</a>
    <em>The Spiritual Magazine </em>
    5 (1870): 114-115. <em>Google Books.</em> Web. 27 Sep. 2013.
</p>
<p>
Hunt, William. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=N1o7AQAAMAAJ&amp;pg=PA355#v=onepage&amp;q&amp;f=false">"The Evolution of a Slum."</a>    <em> The London Quarterly and Holborn Review</em> 99 (Jan. - Apr. 1903): 355-64. <em>Google Books.</em> Web. 20 Oct. 2013.
</p>
<p>
    Hurst, Dorothea E..<strong> </strong><em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=pPQVAAAAYAAJ">The History and Antiquities of Horsham.</a> </em>2nd ed.
    Lewes: Farncombe &amp; Co., 1889. <em>Google Books.</em> Web. 7 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.nhs.uk/Conditions/Hypothermia/Pages/Treatment.aspx">"Hypothermia – Treatment."</a>
    <em>National Health Service,</em>
    28 May 2013.<em> </em>Web. 8 Jun. 2014.
</p>
<p>
    <em>The International Encyclopedia of Surgery.</em>
    Rev ed. 6 vols. John Ashhurt, Editor. New York: William Wood &amp; Company, 1888. <em>Google Books.</em> Web. 9 Oct. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=WPffAAAAMAAJ&amp;pg=PA216#v=onepage&amp;q&amp;f=false">"The Irish Question."</a>
    <em>The Liberal Unionist </em>
    1 (29 Jun. 1887): 216-7. <em> Google Books.</em> Web. 27 Sep. 2013.
</p>
<p>
Jennings, Charles Egerton.    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=-SYDAAAAQAAJ">Transfusion: Its History, Indications and Modes of Application.</a> </em>London: Bailliere,
    Tindal, and Cox, 1883. <em>Google Books.</em> Web. 10 Mar. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.jewishencyclopedia.com/"><em>The Jewish Encyclopedia.</em></a>
    1906. Web. 1 Jun. 2014
</p>
<p>
    Johnson, Major E. C.
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=ou9avD2kdlkC">On the Track of the Crescent: Erratic Notes from Pir</a>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=ou9avD2kdlkC">æ</a>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=ou9avD2kdlkC">us to Pesth</a>
        .
    </em>
    London: Hurst and Blackett, 1885.
</p>
<p>
Johnston, R.W. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=ZfnNAAAAMAAJ&amp;pg=PA6#v=onepage&amp;q&amp;f=false">"Civil Service Shopkeeping."</a>    <em>Chamber's Journal</em> 6.2 (Dec. 1898 – Nov. 1899): 6-9. <em>Google Books.</em> Web. 6 Jun. 2013.
</p>
<p>
    Johnson, Samuel.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/stream/dictionaryofengl01johnuoft#page/n5/mode/2up">
        <em>A Dictionary of the English Language in which the Words are deduced from their Originals, </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/stream/dictionaryofengl01johnuoft#page/n5/mode/2up"><em> </em></a>
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/stream/dictionaryofengl01johnuoft#page/n5/mode/2up">
            and Illustrated in their Different Significations by Examples from the Best Writers.
        </a>
    </em>
    6th ed. 2 vols. London: J. F. and C. Rivington, 1785. <em>Internet Archive.</em> Web. 1 May. 2014.
</p>
<p>
Jones, John.    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=e8ABAAAAQAAJ"><em>The Natural and the Supernatural or Man Physical, Apparitional and Supernatural</em></a>.
    London: H. Bailliere, 1861. <em>Google Books.</em> Web. 24 Jun. 2013.
</p>
<p>
    Jonson, Ben. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=zts-AAAAYAAJ"><em>Poetical Works of Ben Jonson.</em></a> Ed. Robert Bell. London: John W. Parker and
    Son, 1856. <em>Google Books.</em> Web. 7 Sep. 2013.
</p>
<p>
    Kelly, James.
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=tsBCAAAAcAAJ">
            A Complete Collection of Scottish Proverbs Explained and Made Intelligible to the English Reader.
        </a>
    </em>
    London: Rodwell &amp; Martin, 1818. <em>Google Books.</em> Web. 29 Sep. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.stellabooks.com/articles/trade_directories.php">"Kelly's Directories."</a>
    <em>Stella &amp; Rose's Books. </em>
    Web. 6 May. 2014.
</p>
<p>
    Kemény, István (2005). <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://mtaki.hu/data/files/310.pdf">"History of Roma in Hungary"</a>. <em>Roma of Hungary. </em>New York:
    Columbia University Press, 2005: 1–69. <em>mtaki.hu. </em>Web. 30 Oct. 2013.
</p>
<p>
    Kent, William. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.newadvent.org/cathen/07783a.htm">"Indulgences."</a> <em>The Catholic Encyclopedia.</em> Vol. 7. New York: Robert
    Appleton Company, 1910. Web. 8 Jun. 2014.
</p>
<p>
    Kiepert, Heinrich.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.lib.uchicago.edu/e/collections/maps/kiepert/G6481-E1-1869-K5.html">
        <em>The Maps of Heinrich Kiepert: Völker- und Sprachen-Karte von Österreich und den</em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.lib.uchicago.edu/e/collections/maps/kiepert/G6481-E1-1869-K5.html"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.lib.uchicago.edu/e/collections/maps/kiepert/G6481-E1-1869-K5.html"><em>Unter-D </em><em> </em><em>onau-Ländern.</em></a>
    Map. [ca 1:3,000,000] University of Chicago Library. Web. 4 May 2014.
</p>
<p>
Kingston, William. H. G.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=XwgGAAAAQAAJ&amp;pg=PA72#v=onepage&amp;q&amp;f=false">"The Vampire; or, Pedro Pacheco and the Bruxa."</a>    <em> Tales for All Ages.</em> London: Bickers &amp; Bush, 1863. 72-80. <em>Google Books</em>. Web. 24 Jun. 2014.
</p>

<p>Klein. E, J, Burdon-Sanderson, Michael Foster, and Lauder Brunton. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=BwZRAAAAYAAJ"><em>Handbook for the Physiological Laboratory</em></a>. 2 vols. Ed. J. Burdon-Sanderson. Philadelphia: Lindsay and Blakiston, 1873. <em>Google Books</em>. Web. 26 Dec. 2014.</p>

<p>
    Klinger, Leslie, ed. <em>The New Annotated Dracula.</em> New York: W. W. Norton &amp; Company, 2008. Print.
</p>
<p>
Knowlson, Sharper T. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.sacred-texts.com/neu/eng/osc/"><em>The Origins of Popular Superstitions and Customs.</em></a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.sacred-texts.com/neu/eng/osc/"><em> </em></a>London: T. Werner Laurie Lts., 1910. <em>Sacredtexts.org.</em> Web. 27 Sep. 2013.
</p>
<p>
    Köpeczi, Béla. ed. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://mek.oszk.hu/03400/03407/html/index.html"><em>History of Transylvania.</em></a> 3 vols. Toronto: Hungarian
    Research Institute of Canada, 2001. <em>Hungarian Electronic Library. </em>Web. 30 Oct. 2013.
</p>
<p>
    Lankester, Ray. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=dPQYAAAAYAAJ"><em>On the Comparative Longevity of Man and Lower Animals.</em></a> London:
    Macmillan and Co., 1870. <em>Google Books.</em> Web. 27 Sep. 2013.
</p>
<p>
Lane, Edward William, Trans.    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=-eKEAAAAIAAJ"><em>The Arabian Nights' Entertainments: Or The Thousand and One Nights</em></a>, Rev ed. New York:
    George Routledge &amp; Sons, 1886. <em>Google Books.</em> Web. 3 Oct. 2013.
</p>
<p>
Lavater, Johann Caspar.<em> <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=0TUGAAAAQAAJ">Essays on Physiognomy: For the Promotion of the Knowledge and the Love of </a></em>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=0TUGAAAAQAAJ"><em> </em></a><em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=0TUGAAAAQAAJ">Mankind.</a> </em>3 vols.
    London: C. Whittingham, 1804. <em>Google Books.</em> Web. 27 Sep. 2013.
</p>
<p>
Lavater, Johann Caspar.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=tT8HAAAAQAAJ"><em>Physiognomy; or, The Corresponding Analogy Between the Conformation of the </em></a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=tT8HAAAAQAAJ"><em> </em></a><em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=tT8HAAAAQAAJ">Features and the Ruling Passions of the Mind</a>. </em>London: Cowie, Low, and Co., 1826.    <em>Google Books.</em> Web. 24 Jun. 2013.
</p>
<p>
Law, Ernest.    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=FcJBAAAAYAAJ">New Guide to the Royal Palace of Hampton Court with a New Catalogue of the Pictures</a>.</em>
    London: George Bell and Sons, 1892. <em>Google Books.</em> Web. 13 Aug. 2013.
</p>
<p>
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=NNwuAAAAIAAJ">The Law Reports. The Public general Statues, passed in the Forty-</a>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=NNwuAAAAIAAJ">Ninth and Fiftieth years of the reign of Her </a>
    </em>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=NNwuAAAAIAAJ"><em> </em></a>
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=NNwuAAAAIAAJ">Majesty Queen Victoria, 1886: with A List of the Local and </a>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=NNwuAAAAIAAJ">Private Acts, Tables Showing the Effect of the </a>
    </em>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=NNwuAAAAIAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=NNwuAAAAIAAJ"><em>Session's Legislation, And a Copious In</em></a>
    Vol. 22. London, Eyre and SpottiseWood, 1886. <em>Google Books.</em> Web. 9 Mar. 2014.
</p>
<p>
Lázár, István. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://mek.niif.hu/02000/02083/02083.htm"><em>Transylvania: A Short History.</em></a> Trans. Thomas J. DeKornfeld.    <em>Hungarian Electronic Library. </em> Web. 10 Jun. 2014.
</p>
<p align="LEFT">
    Leatherdale, Clive, ed. <em>Dracula Unearthed. </em>2nd Ed. Westcliff-on-Sea: Desert Island Ebooks, 2012. File.
</p>
<p>
Leighton, Robert.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=jUBIAAAAIAAJ"><em> Dogs and All about Them.</em></a> London: Cassel and Company, 1910.    <em>Google Books.</em> Web. 6 Oct. 2013.
</p>
<p>
Lee, Katharine. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=RCAGAAAAQAAJ&amp;">In London Town</a>.</em> London: Richard Bently and Son, 1884.    <em>Google Books.</em> Web. 27 Sep. 2013.
</p>
<p>
    Lee, Sarah. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.gutenberg.org/files/21973/21973-h/21973-h.htm"><em>Anecdotes of the Habits and Instinct of Animals.</em></a> London: Grant
    and Griffith, 1853. <em>Project Gutenberg.</em> Web. 18 Sep. 2013.
</p>
<p>
Lee, Sarah.    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?pg=PA105&amp;id=mfM-AAAAYAAJ">Anecdotes of the Habits and Instincts of Birds, Reptiles, and Fishes.</a> </em>
    London: Grant and Griffith, 1853. <em>Google Books.</em> Web. 18 Sep. 2013.
</p>
<p>
    Lee, Sidney, ed. <em>The Dictionary of National Biography. </em>New York: Macmillan and Co, 1892. <em>Google Books.</em> Web. 28 Nov. 2013.
</p>
<p>
    Le Fanu, Joseph Sheridan. "Carmilla."<em> <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=0FLQAAAAMAAJ">In a Glass Darkly</a>. </em>London: Richard Bentley &amp;
    Son, 1886. <em>Google Books.</em> Web. 25 Jun. 2013.
</p>
<p>
    Le Vasseur, P. C. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=maRWAAAAcAAJ"><em>A French Student's Vademecum: or Indispensable Companion</em></a>.
    Birmingham: Jabet, 1809. <em>Google Books.</em> Web. 8 Jun. 2014.
</p>
<p>
Liddell, Henry Thomas. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=XN4v8V0d2XMC"><em>The Wizard of the North: The Vampire Bride, and Other Poems.</em></a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=XN4v8V0d2XMC"> </a>Edinburgh: William Blackwood, 1833. <em>Google Books</em>. Web. 27 Jun. 2014.
</p>
<p align="LEFT">
Light, Duncan. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://blooferland.com/drc/images/07Light.rtf">"The People of Bram Stoker’s Transylvania."</a>    <em>The Journal of Dracula Studies </em>7 (2005). Web. 4 Nov. 2013.
</p>
<p>
    Linder, Douglas. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://law2.umkc.edu/faculty/projects/ftrials/wilde/wilde.htm"><em>Famous World Trials: The Trials of Oscar Wilde.</em></a> UMKC
    School of Law. Web. 5 Jun. 2014.
</p>
<p>
    Lindermans, Micha F. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.pantheon.org/"><em>Encyclopedia Mythica,</em></a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.pantheon.org/"><em> </em></a>1997-2007. Web.
    10 May. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=F1w3AQAAMAAJ&amp;pg=RA1-PA265#v=onepage&amp;q=Volume%20LXXXVIII&amp;f=false">
        "Literary and Scientific Intelligence."
    </a>
    <em>The Scots Magazine and Edinburgh Literary Miscellany </em>
    9 (Jul. - Dec. 1821): 265-73. <em>Google Books.</em> Web. 2 Oct. 2013. <span >[Preview Only]</span>
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.robin-hoods-bay.co.uk/robin-hoods-bay/history">"Local History."</a>
    <em>Robin Hood's Bay Tourism Association. </em>
    Web. 3 Jun. 2014.
</p>
<p>
    Lochman, Charles.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=tlJEAAAAYAAJ">
        <em>Dose and Price Labels of All Drugs and Preparations of the United States Pharmacopæia of </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=tlJEAAAAYAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=tlJEAAAAYAAJ">
        <em>1880, Together with Many Unofficial Articles That Are Frequently Called For as Medicines or Used in </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=tlJEAAAAYAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=tlJEAAAAYAAJ">
        <em>the Arts, with and Appendix Containing a Description of Many of the New Remedies Lately Introduced, </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=tlJEAAAAYAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=tlJEAAAAYAAJ"><em>For the Use of Pharmacists, Physicians, and Students</em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=tlJEAAAAYAAJ">.</a>
    <em> </em>
    Philadelphia: Dunlap &amp; Clarke, 1891. <em>Google Books.</em> Web. 24 Aug. 2013.
</p>

<p>Lombroso, Gina. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.archive.org/stream/criminalmanaccor00lomb#page/n11/mode/2up">Criminal Man</a>.</em> New York: G. P. Putnam's Sons, 1911. <em>Internet Archive.</em> Web. 24 Jun. 2013</p>
<p>
Lombroso, Cesare. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/stream/manofgenius00lombuoft#page/n11/mode/2up"><em>The Man of Genius.</em></a> London: Walter Scott, 1891.    <em>Internet Archive.</em> Web 1 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://archive.spectator.co.uk/article/28th-september-1889/13/-london-day-by-day">"London Day by Day."</a>
    <em>The Spectator </em>
    28 (Sep. 1889): 13-4.<em> </em>Web. 27 Oct. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=nRJbAAAAYAAJ&amp;pg=PA434#v=onepage&amp;q&amp;f=false">"The London Telephone Service."</a>
    <em>The Electrician</em>
    28 (6 Nov. 1891 – 29 Apr. 1892): 434-7. <em>Google Books.</em> Web. 2 Oct. 2013.
</p>
<p>
    Ludlam, Harry. <em>A Biography of Dracula: The Life of Bram Stoker.</em> London: W. Foulsham &amp; Co. Ltd. 1962. Print.
</p>
<p>
    Lussier, Patrick, dir. <em>Dracula 2000.</em> Dimension Films, 2000. Film.
</p>
<p>
    Lysons, Daniel.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=dpcjAQAAMAAJ">
        <em>The Environs of London, Being an Historical Account of the Towns, Villages, and Hamlets Within </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=dpcjAQAAMAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=dpcjAQAAMAAJ"><em>Twelve Miles of that Capital: Interspersed with Biographical Anecdotes.</em></a>
    2nd ed. 2 vols.<em> </em>London: T. Cadell and W. Davies, 1811. <em>Google Books.</em> Web. 20 Oct. 2013.
</p>
<p>
    Maakaron, Joseph E. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://emedicine.medscape.com/article/198475-overview#showall">"Anemia."</a> <em>Medcape,</em> 30 Jul. 2013.<em> </em>Web. 6
    Jun. 2014.
</p>
<p>
    Máchal, Jan. "Slavic Mythology." Trans. F. Krupička. <em>Vampire Lore: From the Writings of Jan Louis Perkowski.</em> Bloomington: Slavica
    Publishers, 2006: 70-118. Print.
</p>
<p>
    Mackay, Charles.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=zLACAAAAIAAJ">
        <em>A Dictionary of Lowland Scotch: With an Introductory Chapter on the Poetry, Humour, and </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=zLACAAAAIAAJ"><em> </em></a>
    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=zLACAAAAIAAJ">Literary History of the Scotish Language and an Appendix of Scottish Proverbs.</a> </em>
    Boston: Ticknot and Co., 1888. <em>Google Books.</em> Web. 8 Jun. 2014.
</p>
<p>
MacNalty, Sir Arthur. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.ncbi.nlm.nih.gov/pmc/articles/PMC1919045/">"Sir John Burdon Sanderson."</a>    <em>Proceedings of the Royal Society of Medicine </em>47.9 (1954): 754-758. <em>National Library of Medicine</em>. Web. 7 Sep. 2013.
</p>
<p>
    Maddin, Guy, dir. <em>Dracula: Pages from a Virgin's Diary. </em>Vonnie Von Helmolt, 2002. Film
</p>
<p>
Maddison, Isabel.    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=fpNDAAAAIAAJ"><em>Handbook of Courses Open to Women in British, Continental and Canadian Universities.</em></a>
    New York: The Macmillan Company, 1896. <em>Google Books.</em> Web. 3 Oct. 2013.
</p>
<p>
    Madge, Henry M.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=VySgAAAAMAAJ&amp;pg=PA824#v=onepage&amp;q&amp;f=false">
        "Brugelmann and Klingelhöffer on the Transfusion of Blood."
    </a>
    <em> The London Medical Record</em>
    2 (1874): 824-825. <em>Google Books.</em> Web. 24 Aug. 2013.
</p>
<p>
    Mann, Anthony D.P., dir. <em>The Terror of Dracula. </em>ADP Mann &amp; Co, 2012. Film.
</p>
<p>
    Manning, Anne. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=0PABAAAAQAAJ"><em>Medowleigh: A Tale of English Country Life.</em></a> 2 vols. London: Richard
    Bentley, 1863. <em>Google Books.</em> Web. 10 Nov. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.walterscott.lib.ed.ac.uk/works/poetry/marmion.html">"Marmion: Synopsis.</a>
    " <em>The Walter Scott Digital Archive.</em> Edinburgh University Library, 19 Dec. 2011. Web. 28 May. 2014.
</p>
<p align="LEFT">
    Marocchino, Kathryn. "Structural Complexity in Bram Stoker's Dracula.<em> The Bram Stoker Society Journal </em>2 (1990): 3-21. Print.
</p>
<p>
    Matéaux, Clara. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=nr4BAAAAQAA">The Story of the Don, Re-Written for Our Young Folks.</a> </em>London:
    Cassell, Petter, and Galpin, 1870. <em>Google Books.</em> Web. 20 Oct. 2013.
</p>
<p>
Mayo, Herbert.    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=rmwXAQAAMAAJ"><em>On the Truths Contained in Popular Superstitions: With an Account of Mesmerism</em></a>. 2nd
    Ed. Edinburgh: William Blackwood and Sons, 1851. <em>Google Books.</em> Web. 25 Jun. 2013.
</p>
<p>
[Mazuchelli, Elizabeth Sara (Nina).] A Fellow of the Carpathian Society.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=bLlnAAAAMAAJ"><em>"Magyarland:" Being the Narrative of Our </em></a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=bLlnAAAAMAAJ"><em> </em></a><em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=bLlnAAAAMAAJ">Travels Through the Highlands and Lowlands of Hungary.</a> </em>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=pblnAAAAMAAJ">2</a> vols. London: Sampson Low, Marston, Searle &amp; Rivington, 1881. <em>Google Books.</em> Web.
    14 Aug. 2013.
</p>
<p>
    McClelland, Bruce A., <em>Slayers and Their Vampires.</em> Ann Arbor: University of Michigan Press, 2006. Print.
</p>
<p>
McElroy, Katy.    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com.au/books?id=3jYGmJuVGI4C">Eclectus Parrots: Everything about Purchase, Care, Feeding, and Housing.</a> </em>Hauppauge:
    Barron's Educational Series, Inc, 2002. <em>Google Books.</em> Web. 8 Jun. 2014.
</p>
<p>
    McManus, Patrick, dir. <em>Dracula Reborn.</em> Halcyon International Pictures, 2012. Film.
</p>
<p align="LEFT">
    McNally, Raymond T., and Radu Florescu. <em>The Essential Dracula</em>. New York: Mayflower Books, 1979. Print.
</p>
<p>
    Mead, Chris. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.birdcare.com/bin/showpage/portraits/ngportrait">"Nightingale</a>." <em>Bird On!,</em> 30 Apr. 1998.<em> </em>Web. 7 Jun.
    2014.
</p>
<p>
Mead, Leon. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=a4nQAAAAMAAJ">"In a Munich Dead House."</a> <em>Frank Leslie's Popular Monthly</em> Jan-Jun. 1894.    <em>Google Books.</em>
</p>
<p>
    Web. 15 Jun. 2013.
</p>
<p align="LEFT">
Melton, J. Gordon.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.cesnur.org/2003/dracula/">"All Things Dracula: A Bibliography of Editions, Reprints, Adaptations, and Translations of </a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.cesnur.org/2003/dracula/"> </a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.cesnur.org/2003/dracula/">Dracula."</a>    <em>Center for Studies of New Religions. </em>Web. 29 Sep. 2014.
</p>
<p>
    Melton, J. Gordon. <em>The Vampire Book: The Encyclopedia of the Undead.</em> Canton: Visible Ink Press, 2011. Print.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.merriam-webster.com/"><em>Meriam Webster On-line.</em></a>
    Web. 4 May 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=5iUoAAAAYAAJ&amp;pg=PA570#v=onepage&amp;q&amp;f=false">
        "The Middle Passage; Or, Suffering of Slave and Free Immigrants."
    </a>
    <em>De Bow's Review and Industrial Resources, Statistics </em>
    22 (1857): 570-583. <em>Google Books.</em> Web. 7 Nov. 2013.
</p>
<p align="LEFT">
    Miller, Elizabeth. "Bram Stoker: A Man of Notes." <em>Bram Stoker: Centenary Essays</em>. Ed. Jarlath Killeen. Dublin: Four Courts Press, 2014. 73-86.
    Print.
</p>
<p align="LEFT">
Miller, Elizabeth.<em> <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=CMRfXDFrBuUC">A Dracula Handbook.</a> </em>Philadelphia: Xlibris 2005.    <em>GoogleBooks. </em>Web. 8. Jun 2014. <span >[Preview Only]</span>
</p>
<p align="LEFT">
    Miller, Elizabeth.<em> Dracula: Sense and Nonsense.</em> eBook ed. Westcliff-on-Sea, Essex: Desert Island Books, 2012. File.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.timeanddate.com/calendar/moonphases.html">"Moon Phase Calculator."</a>
    Time and Date AS.<em> </em>Web. 4 May. 2014.
</p>
<p>
    Morgentaler, "Blood Transfusions in the Nineteenth Century." <em>Dracula: A Documentary Volume.</em> Ed. Elizabeth Miller. New York: Pegasus Books, 2009.
    155-161. Print.
</p>
<p>
    Morrison, Arthur. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=lMskAQAAIAAJ&amp;pg=PA33#v=onepage&amp;q&amp;f=false">"Zig-Zag Canine."</a> The Stand 6 (Jul. -
    Dec. 1893): 33-40. <em>Google Books.</em> Web. 7 Jun. 2014.
</p>
<p>
Morsely, A. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=p5I9AAAAYAAJ&amp;pg=PA18#v=onepage&amp;q&amp;f=false">"Lucky and Unlucky Days for Fishing."</a>    <em>Annual Report of the Fish Commissioner of the State of Virginia</em> 20 Nov. 1877: 18-20. <em>Google Books. </em>Web. 20 Nov. 2013.
</p>
<p>
    Morton, John.
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=RyMOAAAAQAAJ">Morton's Sixpenny Almanack and Diary with Compendium.</a>
        London: Simpkin, Marshall &amp; Co.
    </em>
    , 1876. <em>Google Books.</em> Web. 2 Oct. 2013.
</p>
<p>
    Moss, "Bram Stoker, Henry Irving, and the Late-Victorian Theatre." <em>Dracula: A Documentary Volume.</em> Ed. Elizabeth Miller. New York: Pegasus Books,
    2009. 139-49. Print.
</p>
<p>
    Moszyński, Kazimierz. "Slavic Folk Culture." Trans. Jan L. Perkowski. <em>Vampire Lore: From the Writings of Jan Louis Perkowski.</em> Bloomington:
    Slavica Publishers, 2006: 212-217. Print.
</p>
<p>
    Murgoci, Agnes. "The Vampire in Romania." <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://openlibrary.org/works/OL15840793W/The_vampire">The Vampire: A Casebook</a>,</em> Ed. Allen
    Dundes. Madison: The University of Wisconsin Press, 1998. 12-34. <em>OpenLibrary.</em> Web. 19 Jun. 2013.
</p>
<p>
    Murnau, F. W., dir.<em> Nosferatu, eine Symphonie des Grauens</em>, Film Arts Guild, 1922. Film.
</p>
<p>
    Murray, Paul. <em>From the Shadow of Dracula: A Life of Bram Stoker.</em> London: Jonathan Cape, 2004. Print.
</p>
<p>
    Murton, Walter.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=NLkDAAAAQAAJ">
        <em>Wreck Inquiries: The Law and Practice Relating to Formal Investigations in the United Kingdom, </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=NLkDAAAAQAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=NLkDAAAAQAAJ">
        <em>British Possessions and before Naval Courts into Shipping Casualties and the Incompetency and </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=NLkDAAAAQAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=NLkDAAAAQAAJ"><em>Misconduct of Ships' Officers.</em></a>
    London: Stevens &amp; Sons, 1884.<em>Google Books.</em> Web. 2 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=OZ4eAQAAMAAJ&amp;pg=P418#v=onepage&amp;q&amp;f=false">"Musical Gossip."</a>
    <em>The Athenaeum Journal of Literature, Science, the Fine Arts, Music and Drama </em>
    Jan. - Jun. 1893: 418.<em> Google Books.</em> Web. 29 Jun. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.newadvent.org/bible/gen001.htm"><em>New Advent Bible.</em></a>
    Westminster Diocese, 2013. Web. 8 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=_fVPAAAAMAAJ"><em>A New Dictionary of Quotations from the Greek, Latin, and Modern Languages.</em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=_fVPAAAAMAAJ"><em> </em></a>
    Philadelphia: J. B. Lippincott &amp; Co., 1869. <em>Google Books.</em> Web. 8 Jun. 2014.
</p>
<p>
    Nichols, Thomas. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=LyiB7PBEvToC">A Handbook for Readers at the British Museum</a>.</em> London: Longman's Green
    &amp; Co, 1866. <em> Google Books.</em> Web. 15 Jun. 2013.
</p>
<p>
    Nisbet, J. H.
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/stream/insanityofgenius00nisb#page/n5/mode/2up">
            The Insanity of Genius and the General Inequality of Human Faculty Physiologically Considered.
        </a>
    </em>
    London: Ward &amp; Downey, 1891. <em>Internet Archive.</em> Web 1 Jun. 2014.
</p>
<p>
    Nizet, Marie. <em>Captain Vampire. </em>Brian Stableford. Trans. Encino: Black Coat Press, 2007. Print.
</p>
<p>
    Nodier, Charles.
    <a href="https://web.archive.org/web/20180228143056mp_/http://www.google.com/url?sa=t&amp;rct=j&amp;q=&amp;esrc=s&amp;source=web&amp;cd=1&amp;ved=0CB8QFjAA&amp;url=http://www.munseys.com/diskone/vampnod.pdf&amp;ei=JILYU_ukEoi2yASOgoL4Aw&amp;usg=AFQjCNH23t4lZowIjlpp8WBC7vP7hHobBw&amp;sig2=FP90A9NDCtL9lhqT8wb6tw&amp;bvm=bv.71778758,d.aWw">
        <em>The Vampire.</em>
    </a>
    Trans. Frank Morlock. Trans. 2000<em>.</em> <em>Munseys. </em>Web. 29 Jul. 2014.
</p>
<p>
Nordau, Max Simon. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=ZkISAAAAYAAJ"><em>Degeneration.</em></a> New York: D. Appleton and Company, 1895.    <em>Google Books.</em> Web. 27 Oct. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.trinityhouse.co.uk/lighthouses/lighthouse_list/north_foreland.html">"North Foreland."</a>
    <em>Trinity House. </em>
    Web. 3 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=DFDNAAAAMAAJ"><em>North Sea Pilot, Part III: East Coast of England.</em></a>
    London: J. D. Potter, 1897. <em>Google Books.</em> Web. 20 Sep. 2013
</p>
<p>
Norton-Taylor, Richard.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.theguardian.com/politics/2005/apr/01/highereducation.artsandhumanities1">"From Dracula's Nemesis to Prototype Foreign Spy."</a>    <em> The Guardian </em>31 Mar. 2005. <em> </em>Web. 15 Dec. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=OAUXAAAAYAAJ&amp;pg=PA391#v=onepage&amp;q&amp;f=false">
        "Notes and Queries – Death of Mr. Charles Jamrach."
    </a>
    <em>The Zoologist: A Monthly Journal of Natural History </em>
    3.15 (1891): 391-2.<em> Google Books.</em> Web. 24 Oct. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://bramstokerestate.com/William-Thornley-Stoker-eldest_brother_of_Bram_Stoker.html">"Obituary: Sir William Thornley Stoker, Bart., M.D.,"</a>
    <em>British Medical Journal</em>
    15 Jun. 1912: 1399–1400. <em>Bram Stoker Estate.</em> Web. 15 Sep. 2013.
</p>

<p>Officer, Lawrence H. and Samuel H. Williamson. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.measuringworth.com/exchange/">"Computing 'Real Value' Over Time With a Conversion Between U.K. Pounds and U.S. Dollars, 1774 to Present."</a> <em>MeasuringWorth</em>, 2014. Web. 7 Jun. 2014.</p>

<p>Officer, Lawrence H. and Samuel H. Williamson. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.measuringworth.com/ukcompare/relativevalue.php">"Five Ways to Compute the Relative Value of a UK Pound Amount, 1270 to Present."</a> <em>MeasuringWorth</em>, 2014. Web.7 Jun. 2014.</p>

<p>Ogbourne, Derek. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.college-optometrists.org/en/college/museyeum/online_exhibitions/eye/optography.cfm">"Optography and Optograms"</a>    <em>The College of Optometrists.</em> Web. 20 Nov. 2013
</p>
<p>
    Oinas, Felix. "East European Vampires." <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://openlibrary.org/works/OL15840793W/The_vampire">The Vampire: A Casebook</a>,</em> Ed. Allen
    Dundes. Madison: The University of Wisconsin Press, 1998: 47-55. <em>OpenLibrary.</em> Web. 4 May. 2014.
</p>
<p>
Olver, Lynne. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.foodtimeline.org/foodfaq7.html">"Food Timeline FAQs: Meals &amp; Holiday Entertaining."</a> <em>Food Timeline,</em> 2014.    <em> </em>Web. 5 May. 2014.
</p>

<p>Oman, John Campbell. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=H6cRAAAAYAAJ"><em>Cults, Customs and Superstitions of India, Being a Revised and Enlarged Edition of "Indian Life, Religious and Social," Comprising Studies and Sketches of interesting peculiarities in the Beliefs, Festivals and Domestic Life of the Indian People; also of Witchcraft and Demoniacal Possession as known amongst them.</em></a> London: T. Fisher Unwin, 1908. <em>Google Books.</em> Web. 27 Sep. 2013.</p>

<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://maps.nls.uk/geo/explore/index.cfm"><em>Ordnance Survey Great Britain One Inch 1885-1900</em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://maps.nls.uk/geo/explore/index.cfm">.</a>
    Map. National Library of Scotland. Web. 10 Aug. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://maps.nls.uk/os/london-1890s/index.html"><em>Ordnance Survey Five Feet to the Mile: London, 1:1,056, 1893-1895.</em></a>
    Map. [ca 1:1,056] National Library of Scotland. Web. 10 May. 2014.
</p>
<p>
    Ossenfelder, Heinrich August. "Der Vampir." <em>The Vampire Book: The Encyclopedia of the Undead.</em> Ed. Gordon J.
</p>
<p>
    Melton. Canton: Visible Ink Press, 2011. 533. Print.
</p>
<p>
Oswald, Felix L. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=QEAwAQAAMAAJ&amp;pg=PA236#v=onepage&amp;q&amp;f=false">"Zoological Curiosities -- Bats."</a>    <em>Lippincott's Monthly Magazine of Popular Literature and Science </em>2 (Sep. 1881): 236-242. <em>Google Books.</em> Web. 27 Sep. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.ordnancesurvey.co.uk/about/overview/history.html">"Our History."</a>
    Ordnance Survey. Web. 4 May. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.rspca.org.uk/in-action/aboutus/heritage">"Our History."</a>
    Royal Society for the Prevention of Cruelty to Animals.<em> </em>Web. 3 Jun. 2014.
</p>
<p>
    Overstreet, Leslie. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.sil.si.edu/DigitalCollections/NHRareBooks/Spix/spix-introduction.htm">"Johann Baptist von Spix: </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.sil.si.edu/DigitalCollections/NHRareBooks/Spix/spix-introduction.htm">
        <em>Simiarum et Vespertilionum Brasiliensium Species Novae</em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.sil.si.edu/DigitalCollections/NHRareBooks/Spix/spix-introduction.htm"> (1823)."</a>
    Smithsonian Institution Libraries, Apr. 2000. Web. 8 Jun. 2014.
</p>
<p>
Ovid [Publius Ovidius Naso]. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.poetryintranslation.com/PITBR/Latin/Ovhome.htm"><em>Metamorphoses.</em></a> Trans. A. S. Kline.    <em>Poetry in Translation,</em> 2000. Web. 2 Jun. 2014.
</p>
<p>
    P., Donald. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://answers.yahoo.com/question/index?qid=20100319235433AAbnT3b">"What is falling lock and spring lock?"</a> <em>Yahoo Answers</em>
    , 2010.<em> </em>Web. 8 Jun. 2014.
</p>
<p>
    Paget, John.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=I4QfAAAAYAAJ">
        <em>Hungary and Transylvania: With Remarks on Their Condition, Social, Political, and Economical</em>
    </a>
    .<em> </em> 2 vols. London: John Murray, 1839. <em>Google Books</em>. Web. 14 Aug. 2013.
</p>
<p>
    Parkinson, Thomas.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=IWhIAAAAYAAJ&amp;pg=PA381#v=onepage&amp;q&amp;f=false">
        "Yorkshire Legends and Traditions, as told by her Ancient Chroniclers, her Ports and
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=IWhIAAAAYAAJ&amp;pg=PA381#v=onepage&amp;q&amp;f=false"> </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=IWhIAAAAYAAJ&amp;pg=PA381#v=onepage&amp;q&amp;f=false">Journalists."</a>
    <em>The Athenaeum </em>
    Jul. - Dec. 1888: 381. <em>Google Books.</em> Web. 24 Jul. 2013.
</p>
<p>
    Pascoe, Charles Eyre. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=BX9HAAAAYAAJ"><em>London of Today: An Illustrated Handbook for the Season</em></a>, 6th ed.
    London: Simpkin, Marshall, Hamilton, Kent &amp; Co, 1890. <em>Google Books.</em> Web. 11 Sep. 2013.
</p>
<p>
Penn, W. A. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=a7gUAAAAYAAJ"><em>The Soverane Herbe: A History of Tobacco.</em></a> London: Grant Richards, 1901.    <em>Google Books.</em> Web. 24 Jul. 2014.
</p>
<p>
    Pepper, William.
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=m0UBAAAAYAAJ">
            Higher Medical Education, the True Interest of the Public and of the Profession. An Address
        </a>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=m0UBAAAAYAAJ"> </a>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=m0UBAAAAYAAJ">
            Introductory to the 112th Course of Lectures in the Medical department of the University of
        </a>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=m0UBAAAAYAAJ"> </a>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=m0UBAAAAYAAJ">Pennsylvania, Delivered October 1, 1877.</a>
    </em>
    Philadelphia, Collins: 1877. <em>Google Books.</em> Web. 7 Mar. 2014.
</p>
<p>
    Perkowski, Jan Louis. <em>Vampires, Dwarves, and Witches Among the Ontario Kashubs. Vampire Lore: From the Writings of Jan Louis Perkowski.</em>
    Bloomington: Slavica Publishers, 2006: 1-56. Print.
</p>
<p>
    Perkowski, Jan Louis. <em>The Darkling: A Treatise on Slavic Vampirism.</em> Columbus: Slavica Publishers, 1989. Print.
</p>
<p>
Perkowski, Jan Louis. "The Romanian Folkloric Vampire."    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://openlibrary.org/works/OL15840793W/The_vampire">The Vampire: A Casebook</a>,</em> Ed. Allen Dundes. Madison: The University of Wisconsin
    Press, 1998: 35-46. <em>OpenLibrary.</em> Web. 19 Jun. 2013.
</p>
<p>
Pettigrew, Thomas Joseph.    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=wNcSDULG3jgC"><em>On Superstitions Connected with the History and Practice of Medicine and Surgery.</em></a>
    London: John Churchill, 1844. <em>Google Books.</em> Web. 11 Oct. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=AmorAAAAYAAJ&amp;pg=PA30">"The Phonograph."</a>
    <em>The Phongraphic Magazine </em>
    2 (1888): 30-31. <em>Google Books.</em> Web. 8 Jun. 2014.
</p>

<p><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=KR8jAQAAMAAJ&amp;pg=PA71#v=onepage&amp;q&amp;f=false">"The Phonograph at the Electric Club: A Brilliant Assembly Listens to Addresses by Mr. Gilland and Prof. Spice – The Phonograph Thoroughly and Satisfactorily Tested."</a> <em>The Electric Review: A Weekly Journal of Electric Light, Power, Telephone, Telegraph and Scientific Progress</em> 12.12 (10 May 1888): 71-73. <em>Google Books.</em> Web. 13 Mar. 2013</p>

<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=BRK1APtXtA4C"><em>A Pictorial and Descriptive Guide to London and Its Environs.</em></a>
    41st rev. ed. London: Ward, Lock &amp; Co., Limited, 1919. <em>Google Books.</em> Web. 30 Sep. 2013.
</p>
<p>
    Pieper, Hans. "Tide Prediction Program." v. 2.4. File.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.visionofbritain.org.uk/place/20405">"Plaistow, Essex."</a>
    <em>A Vision of Britain Through Time. </em>
    University of Portsmouth, 2009. Web. 10 Jun. 2014.
</p>
<p>
Planché, James. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://sites.google.com/site/vampyresite/vampires/the-vampire-or-the-bride-of-the-isles"><em>The Bride of the Isles</em></a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://sites.google.com/site/vampyresite/vampires/the-vampire-or-the-bride-of-the-isles">.</a> <em>The Vampyre Site.</em> Web. 29 Jul. 2014.
</p>
<p>
    Proverbs, D., et al, eds. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=ChttGbIEYfUC">Flood Recovery, Innovation and Response III.</a> </em>Ashurst: WIT
    Press, 2012. <em>Google Books</em>. Web. 10 Jun. 2014. <span >[Preview Only]</span>
</p>
<p>
    Pinkerton, Mark. "Why Westenra?"<em>The Bram Stoker Society Journal</em> 7 (1995): 12-14. Print.
</p>
<p>
[Polidori, John.] <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=ZMsBAAAAQAAJ"><em>The Vampyre: A Tale</em></a>. London: Sherwood, Neely, and Jones, 1819.    <em>Google Books.</em> Web. 25 Jun. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=FXINAAAAYAAJ"><em>The Port of London and the Thames Barrage: A Series of Expert Studies and Reports.</em></a>
    Bloomsbury: Swan Sonnenschein &amp; Compy, Ltd., 1907. <em>Google Books.</em> Web. 2 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.pedalinghistory.com/PHhistory.html">"A Quick History of the Bicycle."</a>
    <em>Peddling History. </em>
    Web. 8 Jun. 2014.
</p>
<p>
    R., E. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=DGgJAAAAQAAJ&amp;pg=PA184#v=onepage&amp;q&amp;f=false">"The Evil Eye."</a> <em>The Cornhill Magazine </em>
    39 (1879): 184-98. <em>Google Books.</em> Web. 19 Jun. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://networkedblogs.com/bI2Km">"Rablohus or Shashlik | Chicken Tikka Saslik."</a>
    <em>Deelish Recipes</em>
    , 2010. Web. 14 Sep. 2013.
</p>
<p>
    Railway Clearing House.<em> <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=CKwEAAAAQAAJ">Regulations of the Railway Clearing House</a>. </em>London: Jas.
    Truscott &amp; Son., 1877. <em>Google Books.</em> Web. 27 Oct. 2013.
</p>

<p>[Raupach, Ernst Benjamin Salomo.] <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://gutenberg.net.au/ebooks06/0606821h.html">"Wake Not the Dead."</a> <em> Project Gutenburg Australia.</em> Web. 30 Apr. 2014. [Incorrectly attributed to Johann Ludwig Tieck.]</p>

<p><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.parliament.uk/business/publications/parliamentary-archives/archives-overview-holdings/archives-overview-official/">"Records of the House of Lords and House of Commons."</a> Great Britain Parliament. Web. 6 May. 2014.</p>

<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=CXcTAAAAYAAJ"><em>Reports from Commissioners, Inspectors and Others: 1888. Vol. 45</em></a>
    . London: Eyre and Spottiswoode, 1889. <em>Google Books.</em> Web. 7 Sep. 2013.
</p>
<p>
    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=_jVcAAAAQAAJ">Reports from Committees.</a> </em>
    Vol 13 (5 Dec. 1878 - 15 Aug. 1879). <em>Google Books.</em> Web. 2 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=L8sHAAAAIAAJ&amp;pg=RA1-PA159#v=onepage&amp;q&amp;f=false">"Resurrectionists." </a>
    <em>The Annual Register, or a View of the History, Politics, and Literature of the Year 1828</em>
    Nov. 1828: 159-60. <em>Google Books.</em> Web. 22 Oct. 2013.
</p>
<p>
    Retterstøl, Nils.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.med.uio.no/klinmed/english/research/centres/nssf/articles/culture/Retterstol1.pdf">
        "Suicide in a cultural history perspective, part I: Western culture; attitudes to suicide up to the
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.med.uio.no/klinmed/english/research/centres/nssf/articles/culture/Retterstol1.pdf"> </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.med.uio.no/klinmed/english/research/centres/nssf/articles/culture/Retterstol1.pdf">19th century."</a>
    <em> </em>
    University of Oslo, 19 May, 2011. Web. 31 May 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.reverso.net/"><em>Reverso</em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.reverso.net/">.</a>
    Reverso-Sofrissimo, 2013. Web. 10 Jun. 2014.
</p>

<p>[Rhymer, James Malcolm.] <a target="_blank" href="http:http://etext.lib.virginia.edu/toc/modeng/public/PreVarn.html"><em>Varney the Vampire.</em></a> Electronic Text Center, University of Virginia Library, 2008. <em>Internet Archive.</em> Web. 5 May. 2014. [Incorrectly attributed to Thomas Preskett Prest.]</p>

<p>
Richardson, Benjamin Ward.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=WqDQAAAAMAAJ&amp;pg=PA719#v=onepage&amp;q&amp;f=false">"Chloral and Other Narcotics."</a>    <em>The Contemporary Review </em>35 (Apr. - Aug. 1879): 719-739. <em>Google Books.</em> Web. 24 Aug. 2013.
</p>
<p>
    Riquelme, John Paul, ed.<em> Dracula</em>.<em> </em>Boston: Beford/St. Martin's, 2002. Print.
</p>
<p>
    Riston, Joseph.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=u-UVAAAAYAAJ">
        <em>A Select Collection of English Songs, with Their Original Airs: and A Historical Essay on the </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=u-UVAAAAYAAJ"><em> </em></a>
    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=u-UVAAAAYAAJ">Origin and Progress of National Song.</a> </em>
    3 vols. London: F. C. and J. Rivington, 1813.
</p>

<p><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.environment-agency.gov.uk/static/documents/Research/Esk_byelaw_report_Final_June_2011.pdf">"River Esk (Yorkshire) Tideway Byelaw Report."</a> Environment Agency, 2001.<em> </em>Web. 24 Jul. 2013.</p>

<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.portcities.org.uk/london/server/show/ConNarrative.66/chapterId/1574/The-riverside-wharves.html">"The Riverside Wharves."</a>
    <em>Port Cities: London.</em>
    Web. 10 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://bayhotel.info/">"Robin Hood's bay – Bay Hotel."</a>
    <em>The Bay Hotel, </em>
    2013. Web. 3 Jun. 2014.
</p>
<p>
    Robinson, F. K. <em>A Glossary of Words Used in the Neighbourhood of Whitby. </em>London: Trübner &amp; Co., 1876. Print.
</p>
<p>
Robert, Paul. Ed. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.typewritermuseum.org/collection/index.php3?machine=blick5&amp;cat=ks">"Blicksenderfer 5."</a>    <em>The Virtual Typewriter Museum. </em>Web. 10 Jun. 2014.
</p>
<p>
Robert, Paul. Ed. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.typewritermuseum.org/collection/index.php3?machine=hansen&amp;cat=kd">"Hansen Writing Ball."</a>    <em>The Virtual Typewriter Museum. </em>Web. 9 May. 2014.
</p>
<p>
    Rodd, Rennell. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=A1UbAAAAMAAJ"><em>The Customs and Lore of Modern Greece</em></a>, 2nd ed. London: David Stott,
    1892. <em>Google Books.</em> Web. 3 Oct. 2013.
</p>
<p>
    Ross, James. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=XHZIAAAAYAAJ">A Treatise on the Diseases of the Nervous System</a>.</em> 2 vols. New York:
    William Wood &amp; Company, 1882. <em>Google Books.</em> Web. 11 Sep. 2013.
</p>
<p>
Routledge, George.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=SLcMAAAAYAAJ"><em>Routledge's Almanack for 1888: A Compendium of Useful and Interesting Information </em></a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=SLcMAAAAYAAJ"><em> </em></a>    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=SLcMAAAAYAAJ">Concerning Our own and Foreign Countries, Colonies, &amp;c.</a> </em> London: George Routledge
    and Sons, 1887. <em>Google Books.</em> Web. 15 Oct. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.jstor.org/discover/10.2307/20293394">"Royal Commission on Vivisection."</a>
    <em>British Medical Journal </em>
    1.2409 (2 Mar. 1907): 507-512. <em>Jstor.</em> Web. 7 Nov. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=7p0tAAAAYAAJ">
        <em>Rules and Regulations of the Windham Club, 11, St. James's Square, with a List of the Members</em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=7p0tAAAAYAAJ">.</a>
    Soho: Nassau Steam Press, 1841. <em>Google Books.</em> Web. 4 Oct. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.princeton.edu/~sailing/PrincetonSailing/Sailing_Tips.html">"Sailing Tips."</a>
    <em>Princeton Sailing Team. </em>
    Web. 3 Jun. 2014.
</p>
<p>
    Salust, C. Crispi.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?oe=UTF-8&amp;id=CYkLAAAAYAAJ">
        <em>Bellum Catilinarium et Jugurthinum: Bellum Catilinarium et Jugurthinum, cum Versione Libera.</em>
    </a>
    John Clarke, Trans. Glouster: R. Raikes, 1789. <em>Google Books.</em> Web. 7 Nov. 2013.
</p>
<p>
Samuelson, James. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=yaQBAAAAQAAJ"><em>Roumania Past and Present.</em></a> London: Longman's Green, and Co., 1882.    <em>Google Books.</em> Web. 29 Jun. 2013.
</p>
<p>
    Sands, Robert C. "The Black Vampyre" <em>The Best Vampire Stories 1800-1849: A Classic Vampire Anthology.</em> eBook ed. Ed. Andrew Barger. Bottletree
    Books LLC, 2012. File.
</p>
<p>
Santor, Kimberly. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.yourparrotcage.com/Bird%20Care/Birds%20Average%20Life%20Span.html">"Parrots Average Life Span."</a>    <em>The Caged Bird Courier,</em> Jul. 2004.<em> </em>Web. 8 Jun.2014.
</p>
<p>
    Sarfraz, Khwaja, dir. <em>Zinda Laash. </em>1967. Film.
</p>
<p>
Satterlee, Francis Le Roy. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=0BAZAAAAYAAJ">Rheumatism and Gout</a>. </em>Detroit: George S. Davis, 1890.    <em>Google Books.</em> Web. 25 Jun. 2013.
</p>
<p>
    Saville, Philip, dir. <em>Count Dracula</em>. BBC. 22 Dec. 1977. Television.
</p>
<p>
    Scäfer, E. A.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=yHQFAAAAQAAJ&amp;pg=PA316#v=onepage&amp;q&amp;f=false">
        "Report of Experimental Inquiry, Instituted to Determine with what Fluids and by what Methods the
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=yHQFAAAAQAAJ&amp;pg=PA316#v=onepage&amp;q&amp;f=false"> </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=yHQFAAAAQAAJ&amp;pg=PA316#v=onepage&amp;q&amp;f=false">
        Operation of Blood-Transfusion May best be Performed, and to Ascertain the Effects, Immediate or
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=yHQFAAAAQAAJ&amp;pg=PA316#v=onepage&amp;q&amp;f=false"> </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=yHQFAAAAQAAJ&amp;pg=PA316#v=onepage&amp;q&amp;f=false">Remote, which Result from the Operation in Animals.</a>
    " <em>Transactions of the Obstetrical Society of London.</em> Vol 21. 1879, 316-47. <em>Google Books</em>. Web. 10 Mar. 2014.
</p>

<p>Schmidt, Barbara. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.twainquotes.com/UniformEds/UniformEdsCh3.html">"A History of and Guide to Uniform Editions of Mark Twain's Works."</a> <em>Twainquotes.com</em> Web. 27 Sep. 2013.</p>

<p>
    Scotland, "Evangelicals, Anglicans, and Ritualism in Victorian England." <em>Churchman </em>3 (1997): 249-265. <em>Churchsociety.org.</em> Web. 6 Mar.
    2013.
</p>
<p>
Scott, Robert H. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=YLsoAQAAMAAJ">Fishery Barometer Manual</a>.</em> London: Eye and Spottiswoode, 1887.    <em>Google Books.</em> Web. 13 Aug. 2013.
</p>
<p>
    Scull, Andrew. <em>The Most Solitary of Afflictions: Madness and Society in Britain, 1700-1900.</em> New Haven: Yale University Press, 1993. Print.
</p>
<p>
Sekon, George Augustus.    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=Xd1AAAAAIAAJ">A History of the Great Western Railway, Being The Story of the Broad Gauge.</a> </em>2nd ed.
    London: Digby, Long &amp; Co., 1895. <em>Google Books.</em> Web. 27 Sep. 2013.
</p>
<p>
    <a target="_blank" href="ttp://www.bl.uk/reshelp/findhelprestype/offpubs/ukofficalpub/servlists/armylist.pdf.%20">"Service lists for the Army, Nacy and Air Force."</a>
    Social Sciences Collection Guides Official Publications. British Library, 2014. Web. 6 May. 2014.
</p>
<p>
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=LhtcAAAAQAAJ">The Sessional Papers Printed by Order of The House of Lords or Presented by </a>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=LhtcAAAAQAAJ">Royal Command in the Session </a>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=LhtcAAAAQAAJ"> </a>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=LhtcAAAAQAAJ">1878-9, Vol. 7.</a>
    </em>
    London: House of Lords, 1879. <em>Google Books.</em> Web. 17 Oct. 2013.
</p>
<p>
Shakespeare, William. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.opensourceshakespeare.org/views/plays/playmenu.php?WorkID=hamlet"><em>Hamlet.</em></a>    <em>Open Source Shakespeare.</em> Web. 11 Mar. 2014.
</p>
<p>
Shakespeare, William. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.opensourceshakespeare.org/views/plays/play_view.php?WorkID=kinglear"><em>King Lear.</em></a>    <em>Open Source Shakespeare.</em> Web. 11 Mar. 2014.
</p>
<p>
Shakespeare, William. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.opensourceshakespeare.org/views/plays/playmenu.php?WorkID=macbeth"><em>Macbeth.</em></a>    <em>Open Source Shakespeare.</em> Web. 11 Mar. 2014.
</p>
<p>
Shakespeare, William. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.opensourceshakespeare.org/views/plays/playmenu.php?WorkID=merchantvenice"><em>The Merchant of Venice.</em></a>    <em>Open Source Shakespeare.</em> Web. 28 May. 2014.
</p>
<p>
Shakespeare, William. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.opensourceshakespeare.org/views/plays/playmenu.php?WorkID=othello"><em>Othello.</em></a>    <em>Open Source Shakespeare.</em> Web. 11 Mar. 2014.
</p>
<p>
Shakespeare, William. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.opensourceshakespeare.org/views/plays/playmenu.php?WorkID=12night"><em>Twelfth Night.</em></a>    <em>Open Source Shakespeare.</em> Web. 11 Mar. 2014.
</p>
<p>
    Shepherd, Leslie. "The Stoker Family on the Move." <em>The Bram Stoker Society Journal </em>10 (1998): 30-35. Print.
</p>
<p>
Sheridan, Robert Brinsley. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=3SRMAAAAcAAJ"><em>The School for Scandal.</em></a> London: John Murray, 1823.    <em>Google Books.</em> Web. 6 Sep. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.themountainguide.co.uk/england/shooters-hill.htm">"Shooter's Hill."</a>
    <em>The Mountain Guide, </em>
    2014. Web. 8 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.royalinstituteofpaintersinwatercolours.org/history.html">"A Short History of the Royal Institute of Painters in Water Colours."</a>
    <em>Royal Institute of Painters in Water Colours.</em>
    Web. 1 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=XAMCAAAAYAAJ&amp;pg=PA121#v=onepage&amp;q&amp;f=false">"The Shorthand Society."</a>
    <em>Shorthand: A Scientific and Literary Magazine </em>
    3 (1889): 121-138. <em>Google Books.</em> Web. 3 May. 2013.
</p>
<p>
    Shuttleworth, Sally.
    <a href="https://web.archive.org/web/20180228143056mp_/http://web.a.ebscohost.com.ezproxy.cpl.org/lrc/detail?vid=3&amp;sid=bc29d37a-eec3-4f81-8e49-96eb179eed9e@sessionmgr4005&amp;hid=4214&amp;bdata=JnNpdGU9bHJjLWxpdmU=#db=lfh&amp;AN=84534544">
        "Spiritual Pathology: Priests, Physicians, and The Way of All Flesh."
    </a>
    <em>Journal of Victorian Studies</em>
    , 54.4 (Summer 2012): 625-53. <em>Literary Reference Center. </em>Web. 3 Jun. 2014
</p>
<p>
    Simkin, John. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://spartacus-educational.com/Jpall.htm">"Pall Mall Gazette."</a> <em>Spartucus Educational,</em> Sep. 1997 - Jun. 2014. 6 Jun.
    2014.<em> </em>
</p>
<p>
Sinclair, Catherine.    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=KVgJAAAAQAAJ">Modern Society: Or, The March of Intellect: The Conclusion of Modern Accomplishments.</a> </em>
    2nd ed. Edinburgh: William Whyte and Co., 1837. <em>Google Books.</em> Web. 20 Nov. 2013.
</p>
<p>
Smith, Huntley. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=wzcEAAAAQAAJ&amp;pg=PA169#v=onepage&amp;q&amp;f=false">"The Vexed Question"</a>    <em>The Churchman's Shilling Magazine and Family Treasury </em>3 (Mar. - Aug. 1888): 169-179. <em>Google Books.</em> Web. 20 Nov. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.esrl.noaa.gov/gmd/grad/solcalc/">"Solar Calculator."</a>
    National Oceanic and Atmospheric Administration. Web. 19 May. 2014.
</p>
<p>
Solomon, Maurice. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=hgM4AAAAMAAJ"><em>Electric Lamps.</em></a> New York: D. Van Nostrand Company, 1908.    <em>Google Books.</em> Web. 5 Oct. 2013.
</p>
<p>
Speed, John Gilner. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=SdDUAAAAMAAJ&amp;pg=PA230#v=onepage&amp;q&amp;f=false">"The Bicycling Era."</a>    <em>Lippincott's Monthly Magazine: A Popular Journal of General Literature, Science, and Politics </em>56 (Jul. - Dec. 1895): 230-8. <em>Google Books.</em>
    Web. 22 Oct. 2013.
</p>
<p>
Stables, Gordon.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=qikGAAAAQAAJ&amp;pg=PA179#v=onepage&amp;q&amp;f=false">"Confessions of an English Chloral-Eater."</a>    <em>Belgravia</em> 6. (Jun. 1875): 179-90. <em>Google Books.</em> Web. 24 Aug. 2013.
</p>
<p>
    Stamp, Cordelia. <em>Dracula Discovered. </em>Whitby: Smith Settle Limited, 2001. Print.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://plato.stanford.edu/index.html"><em>Stanford Encyclopedia of Philosophy.</em></a>
    <em>The Metaphysics Research Lab,</em>
    2014. Web. 10 Jun 2014.
</p>
<p>
Stenbock, Eric. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://gutenberg.net.au/ebooks06/0606601h.html">"The True Story of a Vampire."</a>    <em>The True Story of a Vampire and More. Project Gutenburg Australia.</em> Web. 30 Apr. 2014.
</p>
<p>
St. John, James Augustus.    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=2OlWAAAAMAAJ"><em>The Hellenes: The History of the Manners of the Ancient Greeks.</em></a> London: Richard
    Bentley, 1844. <em>Google Books.</em> Web. 3 Oct. 2013.
</p>

<p>Stoker, Bram. "Author's Preface." <em>A Bram Stoker Omnibus</em>, London: Guild Publishing, 1986.<em> </em>11-12. Print.</p>

<p>Stoker, Bram. "Bengal Roses (1898)." <em>The Lost Writings of Bram Stoker.</em> Ed. John Edgar Browning. New York: Palgrave Macmillan, 2012. 93-111. Print.</p>

<p>Stoker, Bram. <em>Bram Stoker's Notes for Dracula: A Facsimile Edition.</em> Eds. Robert Eighteen-Bisang and Elizabeth Miller. Jefferson: Mcfarland &amp; Company, 2008. Print.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.bramstoker.org/pdf/stories/04other/buriedts.pdf">"Buried Treasures."</a> <em>The Shamrock </em>13-20 Mar. 1875. <em>bramstoker.org.</em> Web. 8 Nov. 2013. [The book is a PDF composed of scanned newspaper segments. Pagination is given based on the page numbers of the PDF file, not of the original newspapers.]</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/stream/BramStoker-DraculasGuestAndOtherWeirdStories/00guesthc#page/n131/mode/2up">"The Burial of Rats."</a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/details/BramStoker-DraculasGuestAndOtherWeirdStories"><em>Dracula's Guest and Other Weird Stories.</em></a> London: George Routledge and Sons, Ltd., 1914. 121-55. <em>Archive.org. </em>Web. 3 Nov. 2013.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.bramstoker.org/pdf/stories/04other/destinyts.pdf">"The Chain of Destiny."</a> <em>The Shamrock</em> 1-22 May 1875. <em>bramstoker.org.</em> Web. 3 Nov. 2013. [The book is a PDF composed of scanned newspaper segments. Pagination is given based on the page numbers of the PDF file, not of the original newspapers.]</p>

<p>Stoker, Bram.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/stream/BramStoker-DraculasGuestAndOtherWeirdStories/00guesthc#page/n105/mode/2up">"The Coming of Abel Behenna."</a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/details/BramStoker-DraculasGuestAndOtherWeirdStories"><em>Dracula's Guest and Other Weird Stories.</em></a> London: George Routledge and Sons, Ltd., 1914. 95-120. <em>Internet Archive.</em> Web. 3 Nov. 2013.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/stream/BramStoker-DraculasGuestAndOtherWeirdStories/00guesthc#page/n181/mode/2up">"Crooken Sands."</a> <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/details/BramStoker-DraculasGuestAndOtherWeirdStories"><em>Dracula's Guest and Other Weird Stories.</em></a> London: George Routledge and Sons, Ltd., 1914. 171-200. <em>Internet Archive.</em> Web. 3 Nov. 2013.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/details/draculabr00stokuoft"><em>Dracula.</em></a> New York: Grosset &amp; Dunlap, 1928. <em>Internet Archive.</em> Web. 29 Sep. 2014.</p>

<p>Stoker, Bram. <em>Dracula: The Rare Text of 1901</em>. White Rock: Transylvania Press, Inc., 1994. Print.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/stream/BramStoker-DraculasGuestAndOtherWeirdStories/00guesthc#page/n167/mode/2up">"A Dream of Read Hands."</a> <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/details/BramStoker-DraculasGuestAndOtherWeirdStories"><em>Dracula's Guest and Other Weird Stories.</em></a> London: George Routledge and Sons, Ltd., 1914. 157-170. <em>Internet Archive. </em>Web. 3 Nov. 2013.</p>

<p>Stoker, Bram. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=ie5kAAAAMAAJ">Famous Imposters.</a> </em>London: Sidgwick &amp; Jackson, Ltd., 1910. <em>Google Books.</em> Web. 28 Sep. 2013.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/details/gateslife00stokgoog"><em>The Gates of Life.</em></a> New York: Cupples &amp; Leon Company, 1908. <em>Internet Archive. </em>Web. 3 Nov. 2013.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/stream/BramStoker-DraculasGuestAndOtherWeirdStories/00guesthc#page/n91/mode/2up">"The Gipsy Prophecy."</a> <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/details/BramStoker-DraculasGuestAndOtherWeirdStories"><em>Dracula's Guest and Other Weird Stories.</em></a> London: George Routledge and Sons, Ltd., 1914. 81-93. <em>Internet Archive. </em>Web. 3 Nov. 2013.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=iR4WAAAAYAAJ"><em>The Jewel of Seven Stars.</em></a> New York: Harper &amp; Brothers, 1904. <em>Google Books.</em> Web. 27 Sep. 2013.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/stream/BramStoker-DraculasGuestAndOtherWeirdStories/00guesthc#page/n29/mode/2up">"The Judge's House."</a> <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/details/BramStoker-DraculasGuestAndOtherWeirdStories"><em>Dracula's Guest and Other Weird Stories.</em></a> London: George Routledge and Sons, Ltd., 1914. 19-44. <em>Internet Archive. </em>Web. 3 Nov. 2013.</p>

<p>Stoker, Bram. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.bramstoker.org/pdf/novels/10athlynees.pdf">Lady Athlyne.</a> The Evening Star</em> 27 Mar – 13 May 1909.    <em>bramstoker.org.</em> Web. 3 Nov. 2013. [The book is a PDF composed of scanned newspaper segments. Pagination is given based on the page numbers of the PDF file, not of the original newspapers.]</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/details/BramStoker-LadyOfTheShroud"><em>The Lady of the Shroud.</em></a> London: William Heinemann, 1909. <em>Internet Archive. </em>Web. 2 Nov. 2013.</p>

<p>Stoker, Bram. <em>The Lair of the White Worm.</em> <em>The Bram Stoker Omnibus Edition Dracula and the Lair of the White Worm. </em>London: Guild Publishing, 1986. 333-511. Print.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.gutenberg.org/ebooks/1188"><em>The Lair of the White Worm.</em></a> Abridged ed. <em>Project Gutenberg.</em> Web. 5 Jun. 2014.</p>

<p>Stoker, Bram.<em> The Dublin Years: The Lost Journal of Bram Stoker. </em>Eds. Elizabeth Miller and Dacre Stoker. London: The Robson Press, 2013. Print.</p>

<p>Stoker, Bram. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.bramstoker.org/novels/09man.html">The Man.</a></em> London: Robert Hayes, Ltd., 1920. <em>bramstoker.org.</em> Web. 2 Nov. 2013. [*The exact date of publication is not given. The book is a text reproduction which does not preserve the pagination of the original.]</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=ik9bAAAAMAAJ"><em>Miss Betty</em></a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=ik9bAAAAMAAJ">.</a> London: Arthur Pearson Limited, 1898. <em>Google Books.</em> Web. 14 Oct. 2013.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.bramstoker.org/pdf/nonfic/workuk.pdf">"Mr. De Morgan's Habits of Work : An Artist, Manufacturer, and Inventor Who Began Writing </a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.bramstoker.org/pdf/nonfic/workuk.pdf"> </a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.bramstoker.org/pdf/nonfic/workuk.pdf">Novels at the Age of Sixty-Four."</a>    <em>The World's Work: An Illustrated Magazine of National Efficiency and Social Progress</em>, Jul. 1908: 161-164. <em>bramstoker.org.</em> Web. 8 Jun. 2014.</p>

<p>Stoker, Bram. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=uJAnAAAAMAAJ">The Mystery of the Sea.</a> </em>New York: Doubleday, Page &amp; Co, 1902. <em>Google Books.</em> Web. 14 Oct. 2013.</p>

<p>Stoker, Bram. "Old Hoggan: A Mystery (1893)." <em>The Lost Writings of Bram Stoker.</em> Ed. John Edgar Browning. New York: Palgrave Macmillan, 2012: 25-55. Print.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://bramstoker.org/pdf/stories/04other/houseta.pdf">"Our New House."</a> <em>The Theatre Annual. </em>23 Nov. 1885. <em>bramstoker.org.</em> Web. 3 Nov. 2013.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/details/personalreminis02stokgoog"><em>Personal Reminiscences of Henry Irving.</em></a> 2 vols. New York: The MacMillian Company, 1906. <em> Internet Archive. </em>Web. 3 Nov. 2013.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.bramstoker.org/pdf/novels/01pathts.pdf"><em>The Primrose Path.</em></a> <em>The Shamrock</em> 6 Feb. – 6 Mar. 1875.    <em>bramstoker.org.</em> Web. 3 Nov. 2013. [The book is a PDF composed of scanned newspaper segments. Pagination is given based on the page numbers of the PDF file, not of the original newspapers.]</p>

<p>Stoker, Bram.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/stream/BramStoker-DraculasGuestAndOtherWeirdStories/00guesthc#page/n73/mode/2up">"The Secret of Growing Gold."</a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/details/BramStoker-DraculasGuestAndOtherWeirdStories"><em>Dracula's Guest and Other Weird Stories.</em></a> London: George Routledge and Sons, Ltd., 1914. 63-80. <em>Internet Archive.</em> Web. 3 Nov. 2013.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/details/BramStoker-TheShoulderOfShasta"><em>The Shoulder of Shasta.</em></a> Westminster: Archibald Constable and Co., 1895. <em>Internet Archive. </em>Web. 1 Nov. 2013.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/stream/BramStoker-DraculasGuestAndOtherWeirdStories/00guesthc#page/n55/mode/2up">"The Squaw."</a> <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/details/BramStoker-DraculasGuestAndOtherWeirdStories"><em>Dracula's Guest and Other Weird Stories.</em></a> London: George Routledge and Sons, Ltd., 1914. 45-62. <em>Internet Archive.</em> Web. 3 Nov. 2013.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/details/snakespass00stok"><em>The Snake's Pass.</em></a> London: Sampson Low, Marston, Searle &amp; Rivington, 1891. <em>Internet Archive. </em>Web. 1 Nov. 2013.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.bramstoker.org/txt/stories/02snow/14trap.txt">"A Star Trap."</a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.bramstoker.org/stories/02snow.html"><em>Snowbound: The Record of a Theatrical Touring Party.</em></a> London: Collier &amp; Co., 1908.    <em>bramstoker.org.</em> Web. 3 Nov. 2013.</p>

<p>Stoker, Bram. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=s2w-AAAAYAAJ">The Watter's Mou.</a> </em>New York: D. Appleton and Company, 1895. <em>Google Books.</em> Web. 22 Sep. 2013.</p>

<p>Stoker, Bram. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.bramstoker.org/txt/stories/04other/peace.txt">"The Way of Peace."</a> <em>Everybody's Story Magazine </em>Dec. 1909.    <em>bramstoker.org.</em> Web. 8 Aug 2014.</p>

<p>Stoker, Bram. "When the Sky Rains Gold (1894)." <em>The Lost Writings of Bram Stoker.</em> Ed. John Edgar Browning, New York: Palgrave Macmillan, 2012. 56-92. Print.</p>

<p>Stoker, Bram. "A Young Widow (1899)." <em>The Lost Writings of Bram Stoker.</em> Ed. John Edgar Browning. New York: Palgrave Macmillan, 2012. 113-8. Print.</p>

<p>Stoker, Florence. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/stream/BramStoker-DraculasGuestAndOtherWeirdStories/00guesthc#page/n7/mode/2up">"Preface."</a> <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/https://archive.org/details/BramStoker-DraculasGuestAndOtherWeirdStories"><em>Dracula's Guest and Other Weird Stories.</em></a> London: George Routledge and Sons, Ltd., 1914. <em>Internet Archive. </em>Web. 30 Apr. 2014.</p>

<p>
Stoker, William Thornley,<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.ncbi.nlm.nih.gov/pmc/articles/PMC1430626/">"On a Case of Subcranial Haemorrhage Treated by Secondary Trephining"</a>,    <em>Annals of Surgery.</em> Vol.7, 1888, 401-410. <em>National Library of Medicine</em>. Web. 7 Sep. 2013.
</p>
<p>
    Storey, Neil. <em>The Dracula Secrets: Jack the Ripper and the Darkest Sources of Bram Stoker.</em> Kindle ed. File.
</p>
<p>
    Strabo. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://penelope.uchicago.edu/Thayer/E/Roman/Texts/Strabo/home.html"><em>Geography.</em></a> Trans. H. L. Jones. 8 vols. Cambridge: Harvard
    University Press, 1917-1932 <em>penelope.uchicago.edu. </em>Web. 7 Mar. 2014.
</p>
<p>
Stradley, Linda. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://whatscookingamerica.net/History/HighTeaHistory.htm">"History of High Tea - History English Afternoon Tea."</a>    <em>What's Cooking America?</em>, 2004. Web. 9 Aug. 2014.
</p>
<p>
    Straw, Jack. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.theguardian.com/commentisfree/2007/apr/23/hewasbornin">"St. George the Turkish Arab."</a> <em>The Guardian</em>, 23 Apr.
    2007. Web. 20 Nov. 2013.
</p>
<p>
Strugglrs. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=r1wEAAAAMAAJ&amp;pg=PA147#v=onepage&amp;q&amp;f=false">"Carter, Paterson and Co., Carriers."</a>    <em>Railway Herald Magazine </em>1 (Apr. 1895): 147-51. <em>Google Books.</em> Web. 13 Sep. 2013.
</p>
<p>
    Sugden, Phillip. <em>The Complete History of Jack the Ripper.</em> Kindle Edition. London: Constable Robinson, 2006. File.
</p>
<p>
Summers, Montague. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://sacred-texts.com/goth/vkk/vkk00.htm">The Vampire: His Kith and Kin.</a> </em>London: K. Paul Trench: 1928.    <em>Sacredtexts.org.</em> Web. 7 Mar. 2014. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://aa.usno.navy.mil/index.php">"Sun or Moon Rise/Set Table for One Year."</a>
    US Navy Astronomical Applications Department,<em> </em>30 Mar. 2012. Web.<em> </em>7 Jun. 2014.<em> </em>
</p>
<p>
Tacitus, Cornelius Gaius.    <em> <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=tZFJAAAAYAAJ">The Agricola of Tacitus: With a Revised Text, English Notes, and Map.</a> </em>Trans. Alfred
    John Church and William Jackson Brodribb. London: Macmillan and Co., 1876. <em>Google Books.</em> Web. 13 Oct. 2013.
</p>
<p>
Taddei, Antonia. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.economics.ox.ac.uk/materials/papers/2264/28taddeiweb1.pdf">"London Clubs in the Late Ninteenth Century."</a>    <em>Discussion Papers In Economic and Social History</em> 28 (Apr. 1999).<em> </em>Oxford University.<em> </em>Web. 9 Jun. 2014.
</p>
<p>
    Terry, Ellen. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=lnMAAAAAYAAJ"><em>The Story of My Life: Recollections and Reflections.</em></a> New York: The
    McClure Company, 1908. <em>Google Books.</em> Web. 17 Aug. 2013.
</p>
<p>
    Tewksbury, George E. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=V2Q5AQAAIAAJ">A Complete Manual of the Edison Phonograph</a>.</em> Kent: G. L. Frow,
    1897. <em>Google Books.</em> Web. 30 Sep. 2013.
</p>
<p>
    Theobald, Sir Henry Studdy. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=wYEzAAAAIAAJ">A Concise Treatise on the Law of Wills.</a> </em>2nd ed. London:
    Stevens and Sons, 1881. <em>Google Books.</em> Web. 8 Jun. 2014.
</p>
<p>
    Thorne, James.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=qjwQAAAAYAAJ">
        <em>Handbook to the Environs of London, Alphabetically Arranged, Containing an Account of Every </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=qjwQAAAAYAAJ"><em> </em></a>
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=qjwQAAAAYAAJ">
            Town and Village, and of all Places of Interest Within a Circle of Twenty Miles Round London.
        </a>
    </em>
    2 vols. London: John Murray, 1876. <em>Google Books.</em> Web. 26 Sep. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=h0nyAAAAMAAJ"><em>Time Tables of the Great Western Railway, January, February, March and April, 1902.</em></a>
    London: J. L. Wilkinson, 1902. <em>Google Books.</em> Web. 20 Oct. 2013.
</p>
<p>
    Tolstoy, Alexis. "The Family of the Vourdalak." <em>Vampires: Stories of the Supernatural. </em>Linda Kuehl ed. New York: HawthornBooks, Inc, 1969. 92-125.
    Print.
</p>
<p>
    Tolstoy, Alexis. "The Vampire." <em>Vampires: Stories of the Supernatural. </em>Linda Kuehl ed. New York: Hawthorn Books, Inc, 1969. 9-91. Print.
</p>
<p>
Toner, Patrick. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.newadvent.org/cathen/04653a.htm">"Prayers for the Dead."</a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.newadvent.org/cathen/04653a.htm"> </a>    <em>The Catholic Encyclopedia.</em> Vol. 4. New York: Robert Appleton Company, 1908. Web. 8 Jun. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.pbs.org/wgbh/buildingbig/wonder/structure/tower.html">"Tower Bridge."</a>
    <em>PBS Databank: Wonders of the World,</em>
    2001. Web 9 Jun. 2014
</p>
<p>
Traubel, Horace. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.whitmanarchive.org/criticism/disciples/traubel/WWWiC/4/whole.html">With Walt Whitman in Camden.</a> </em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.whitmanarchive.org/criticism/disciples/traubel/WWWiC/4/whole.html">Vol. 4.</a> Philadelphia: Univ. of Pennsylvania Press, 1953.    <em>Whitman Archive. </em>Web. 17 Sep. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=wg8SAAAAYAAJ&amp;pg=PA468-IA40#v=onepage&amp;q&amp;f=false">
        "The Traveller's Typewriter is the Blickensderfer."
    </a>
    <em>The Geographical Journal </em>
    30 (Jul. - Dec. 1907): 468. <em>Google Books.</em> Web. 28 Oct. 2013.
</p>
<p>
    Tuckey, James.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=d8MBAAAAYAAJ">
        <em>Maritime Geography and Statistics, or a Description of the Ocean and its Coasts, Maritime </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=d8MBAAAAYAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=d8MBAAAAYAAJ"><em>Commerce</em></a>
    , Navigation. 4 vols. London: Black, Parry, and Co., 1815. <em>Google Books.</em> Web. 7 Nov. 2013.
</p>
<p>
    Twain, Mark. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=h8VCAAAAYAAJ"><em>Following the Equator: A Journey Around the World.</em></a> Hartford: The American
    Publishing Company, 1898. <em>Google Books.</em> Web. 27 Sep. 2013.
</p>
<p>
    Ulrichs, Karl Heinrich. "Manor." Hubert Kennedy, trans. <em>Embracing the Dark.</em> Boston: Alyson Publications, Inc, 1991. 98-108. Print.
</p>
<p>
Upton, Clive. et al. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=3exEAQAAQBAJ"><em>Survey of English Dialects.</em></a> London: Routledge, 1994.    <em>Google Books.</em> Web. 10 Jun. 2014. <span >[Preview Only]</span>
</p>
<p>
    Unger, Steven P.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.romaniatourism.com/dracula-travel-journal-in-the-footsteps-of-dracula.html">
        "The Writing of "In the Footsteps of Dracula": A Personal Journey and Travel Guide."
    </a>
    <em>Romania Tourism. </em>
    Web. 8 Mar. 2013.
</p>
<p>
    Vukanović, T. P. "The Vampire." <em>Vampire Lore: From the Writings of Jan Louis Perkowski.</em> Bloomington: Slavica Publishers, 2006: 230-259.
    Print.
</p>
<p>
    Walford, Edward.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=jXcuAAAAMAAJ">
        <em>Old and New London: a Narrative of Its History, Its People, and Its Places: The Western and </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=jXcuAAAAMAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=jXcuAAAAMAAJ"><em>Northern Suburbs</em></a>
    , Vol. 5. London: Cassell &amp; Company, Limited, 1892. <em>Google Books.</em> Web. 30 Sep. 2013.
</p>
<p>
    Walker, Gerald and Lorraine Wright. "Locating Dracula: Contextualising the Geography of Transylvania." <em>Dracula: Sucking Through the Centuries</em>. Ed.
    Carol Margaret Davison. Toronto: Dundurn Press, 1997. Print.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://discovery.nationalarchives.gov.uk/SearchUI/details?Uri=C14274">"War Office: Printed Quarterly Army Lists."</a>
    Great Britain National Archives. Web. 6 May. 2014.
</p>
<p>
    Ward, Artemas.
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=zVpaAAAAMAAJ">
            The Complete Works of Artemus Ward: With A Biographical Sketch (by Melville D. Landon, "Eli
        </a>
    </em>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=zVpaAAAAMAAJ"><em> </em></a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=zVpaAAAAMAAJ"><em>Perkins") and Many Humourus Illustrations.</em></a>
    Rev. ed. Ed. Charles Farrar Browne. New York: G. W. Dilingham Co., 1898. <em>Google Books.</em> Web. 13 Sep. 2013.
</p>
<p>
    Ward, Artemas. <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=H5iiMNfoYQIC">The Grocer's Encyclopedia</a>. </em>New York: The James Kempster Printing
    Company, 1911. <em>Google Books.</em> Web. 19 Jun. 2013.
</p>
<p>
Ward, Christine. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.vikinganswerlady.com/ghosts.shtml">"The Walking Dead: Draugr and Aptrgangr in Old Norse Literature."</a>    <em>The Viking Answer Lady, </em>19 Jun. 2014. Web. 19 Jun. 2014.
</p>
<p>
Ward, Mrs. Humphrey. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=NhuKvfxFTwAC"><em>Eltham House: A Novel</em></a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=NhuKvfxFTwAC">.</a> New York: Hearst's International Library Company, 1915. <em>Google Books.</em> Web. 20 Oct.
    2013.
</p>
<p>
Warren, Samuel. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=s6EwAAAAYAAJ"><em>Ten Thousand A-Year.</em></a> 2 vols. Boston: Little, Brown, and Company, 1889.    <em>Google Books.</em> Web. 20 Oct. 2013.
</p>
<p>
Waterman, Catherine H.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=Kgg0AQAAMAAJ"><em>Flora's Lexicon: An Interpretation of the Language and Sentiment of Flowers with an </em></a><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=Kgg0AQAAMAAJ"><em> </em></a>    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=Kgg0AQAAMAAJ">Outline of Botany and a Poetical Introduction.</a> </em>Boston: Phillips, Sampson and Company,
    1852. <em>Google Books.</em> Web. 8 Jun. 2014.
</p>
<p>
    Wayne, Tiffany.<em> <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=hckauAdkix0C">Encyclopedia of Transcendentialism.</a></em> New York: Facts on File, Inc.,
    2006. <em>Google Books.</em> Web. 27 Oct. 2013. <span >[Preview Only]</span>
</p>
<p>
Weatherill, Richard.<a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=d6gZAAAAYAAJ"><em>The Ancient Port of Whitby and Its Shipping: With Some Subjects of Interest Connected </em></a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=d6gZAAAAYAAJ"><em> </em></a><em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=d6gZAAAAYAAJ">Therewith</a>. </em>
    Whitby: Horne and Son, 1908. <em>Google Books.</em> Web. 24 Jul. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=nolNAAAAYAAJ&amp;pg=PA112#v=onepage&amp;q&amp;f=false">"The Westminster Gazette,"</a>
    <em>The Speaker: A Review of Politics, Letters, Science and the Arts </em>
    7 (28 Jan. 1893): 11.<em> Google Books.</em> Web. 28 Sep. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://archive.spectator.co.uk/article/11th-february-1893/3/the-westminster-gazette-the-new-evening-liberal-pa">"The Westminster Gazette,"</a>
    <em>The Spectator</em>
    11 Feb. 1893: 3. Web. 23 Oct. 2013.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.emergencycareforyou.org/EmergencyManual/WhatToDoInMedicalEmergency/Default.aspx?id=258">
        "What to do in a Medical Emergency: Neck or Back Injury."
    </a>
    <em>Emergency Care for You.</em>
    American College of Emergency Physicians. Web. 9 Jun. 2014.
</p>
<p>
White, Caroline A. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=QUcFAAAAQAAJ&amp;pg=PA28#v=onepage&amp;q&amp;f=false">"Hampstead and the Heath."</a>    <em>Ladies Home Companion </em>35 (1869): 28-36. <em>GoogleBooks.</em> Web. 5 Jul. 2014.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://www.bbc.co.uk/news/uk-10782099">"Who are the Gurkhas?"</a>
    <em>BBC News UK</em>
    , 27 Jul. 2010. Web. 26 Oct. 2013.
</p>
<p>
    Wilde, Lady Jane Fransesca Elgee.
    <em>
        <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=AmQZAAAAYAAJ">Ancient Legends, Mystic Charms, and Superstitions of Ireland</a>
        : With Sketches of the Irish Past
    </em>
    . Boston: Ticknot and Company, 1888. <em>Google Books. </em>Web. 3 May. 2014.
</p>

<p>Wilkinson, William. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=RogMAQAAMAAJ"><em>An Account of the Principalities of Wallachia and Moldavia with Various Political Observations Relating to Them</em></a>. London: Longman, Hurst, Rees, Orme, and Brown, 1820. <em>Google Books. </em>Web. 14 Aug. 2013.</p>

<p>Willing, James. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=V0UCAAAAYAAJ"><em>Willing's British &amp; Irish Press Guide and Advertiser's Directory and Handbook</em></a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=V0UCAAAAYAAJ">. </a> 21st ed. London: James Willing, 1894. <em>Google Books.</em> Web. 26 Sep. 2013.</p>

<p>
    Willis, William Gorman. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=Rh4OAAAAQAAJ"><em>Faust, In a Prologue and Five Acts.</em></a> <em>Google Books.</em>
    Web. 8 Aug. 2014.
</p>
<p>
Wilson, Katharina M. "The Origin of the Word <em>Vampire</em>."    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://openlibrary.org/works/OL15840793W/The_vampire">The Vampire: A Casebook</a>,</em> Ed. Allen Dundes. Madison: The University of Wisconsin
    Press, 1998. 35-46. <em>OpenLibrary.</em> Web. 4 Oct. 2013.
</p>

<p>Wimmer, Sebastian J. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=6dkZuky15fsC"><em>Physician's Vade Mecum: Being a Hand-Book of Medical and Surgical Reference, with Other Useful Information Tables</em></a><em>.</em> Philadelphia: The Medical Publishing Company, 1894. <em>Google Books.</em> Web. 24 Sept. 2013.</p>

<p>
    Wolf, Leonard. <em>The Essential Dracula. </em>New York: iBooks Inc., 2004. Print.
</p>
<p>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://paperspast.natlib.govt.nz/cgi-bin/paperspast?a=d&amp;d=NZH18950202.2.67.17">"Women Stabbed in London."</a>
    <em>New Zealand Herald</em>
    32.9734 (2 Feb. 1895): 2. <em>Papers Past.</em> Web. 28 May 2014.
</p>
<p>
Wright, Joseph. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=HIJBAQAAIAAJ"><em>The English Dialect Dictionary</em></a>    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=HIJBAQAAIAAJ"><em>.</em></a> 6 vols. London: Henry Froud, 1898. <em>Google Books.</em> Web. 30 Oct. 2013.
</p>
<p>
    Young, George.
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=hh4wAAAAYAAJ">
        <em>A History of Whitby, and Streoneshalh Abbey; with a Statistical Survey of the Vicinity to the </em>
    </a>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=hh4wAAAAYAAJ"><em> </em></a>
    <em><a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=hh4wAAAAYAAJ">Distance of Twenty-Five Miles</a>. </em>
    <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=1MIHAAAAQAAJ">2</a>
    vols. Whitby: Clark and Medd, 1817. <em>Google Books.</em> Web. 24 Jul. 2013
</p>
<p>
Young, George. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=KboLAAAAYAAJ"><em>A Picture of Whitby and its Environs</em></a>. Whitby: R. Rodgers, 1824.    <em>Google Books.</em> Web. 8 Aug. 2013.
</p>
<p>
    Young, Roger, dir. <em>Dracula's Curse. </em>Sirio Vide. <em>29 May 2002</em>. Television.
</p>
<p>
    Zangwill, Israel. <a target="_blank" href="https://web.archive.org/web/20180228143056mp_/http://books.google.com/books?id=gXFBAAAAYAAJ&amp;pg=PA278#v=onepage&amp;q&amp;f=false">"The Speeches."</a> <em>The Critic </em>
    30 (Jul. - Dec. 1898): 278-282. <em>Google Books.</em> Web. 27 Sep. 2013.
</p>
</div>
</div>);
}

export default Bibliography;