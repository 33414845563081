function About() {
    return (<div id="text">
<div className="inner">
<h1>About</h1>

<p>Welcome to the Dracula Project!</p>

<p>This endeavor came about more or less by accident. In the late fall of 2012, I reread <em>Dracula</em> for the first time since I had encountered it as teenager thumbing through a bent Dover Thrift copy (which I digested in a matter of weeks before deciding I much preferred the movies). Rereading the story with the advantage of several years of experience, however, I found myself newly entranced by its narrative and became a rather zealous fan of <em>Dracula</em> and its author.</p>

<p>I spent the next year reading through a great deal of Bram Stoker's other novels and stories, eager to find out more about the creative life of the man who had penned my new favorite book. Being a child of the digital age, I appended notes to my text file of <em>Dracula</em> every time I found an interesting similarity between one of Stoker's works and the text, and in doing so, I came to accumulate a large enough body of observations that I thought it not a bad idea to just keep on annotating whatever struck my fancy. Over time, the project grew larger and larger in scope, and my friends, family, and acquaintances (some of whom were rather tired of hearing about <em>Dracula</em> at this point) suggested that I ought put it somewhere where the rest of the world might enjoy it. The result is the website you see before you.</p>
<p></p>

<p>I don't pretend that this project will adequately address or explain everything contained within the story. <em>Dracula</em> is a work too labyrinthine, inconsistent, and dense with possible meanings to every fully unravel. However, I believe that this work shines light on some areas not covered by the currently available annotated editions of the text. By the very nature of how it came about, it focuses frequently on the ways in which <em>Dracula</em> is connected to Stoker's other writing. It also attempts to place <em>Dracula</em> in the context of the larger body of 19th century vampire literature; to highlight possible connections between <em>Dracula's</em> content and the sources Stoker may have consulted in his research for the book; and to correct a few long-standing errors that have been repeated by past annotators. It is my hope, as well, that the digital format through which I've released this work will afford me the opportunity to continue to improve upon my work as resources become available to me and as new scholarship is penned.</p> 

<p>My primary hope, however, is that it proves both useful and informative to those who share my interest in and my passion for <em>Dracula</em> and its author.</p>

<p style={{textAlign: 'right'}}>- L. Davydov</p>

<hr />

<h1>Notes on the Text</h1>

<p>The text follows that of the 1897 Constable &amp; Co. edition. Portions of the text omitted in the 1901 abridged paperback edition have been dimmed, and notes have been included throughout the work discussing the differences between the 1897 edition, the 1901 abridgment, and the printing tradition following the 1899 American edition.</p>

<p>Where possible, I have included hyperlinks to sources in the public domain, such that interested readers may retrace my research. I have additionally provided notes as to which glosses in the works of other <em>Dracula</em> annotators most closely mirror any given footnote of mine, in the event that anyone reading might enjoy a second opinion. Currently, the other annotated works I reference in this fashion include: Leonard Wolf's <em>The Essential Dracula</em> (2004 Revised ed.), Raymond McNally and Radu Florescu's <em>The Essential Dracula</em> (1979), Nina Auerbach and David J. Skal's <em>Dracula</em> (Norton Critical Edition, 1997), Glennis Byron's <em>Dracula</em> (1998), Clive Leatherdale's <em>Dracula Unearthed</em> (1998), John Paul Riquelme's <em>Dracula</em> (Case Studies in Contemporary Criticism, 2002), and Leslie Klinger's <em>The New Annotated Dracula</em> (2008).</p>

<hr />
<h1>Contact</h1>
<p>To contact the authors of this site, email us at thedraculaprojectATgmailDOTcom.</p>

</div>
</div>)};

export default About;