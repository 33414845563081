import { render } from "@testing-library/react";
import ReactMarkdown from 'react-markdown'
import directive from "remark-directive";
import customDirectives from "./customDirectives";
import rehypeRaw from 'rehype-raw'

import { useParams } from 'react-router-dom';
import React, { useState } from "react";

function Markdown(props) {

    let { passageId } = useParams()

    const [noteId, setNoteId] = useState('');

    return(
        <ReactMarkdown
            components={{...props.components}}
            rehypePlugins={[rehypeRaw]} 
            remarkPlugins={[directive, customDirectives]}
            children={props.text} />
    );
}

export default Markdown;